import * as React from "react";

function SvgI16ExitDark(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
      <defs>
        <path
          id="i-16-exit_svg__a"
          d="M2.293 2.293a1 1 0 011.414 0L8 6.585l4.293-4.292a1 1 0 011.414 1.414L9.415 8l4.292 4.293a1 1 0 01.083 1.32l-.083.094a1 1 0 01-1.414 0L8 9.415l-4.293 4.292a1 1 0 11-1.414-1.414L6.585 8 2.293 3.707a1 1 0 01-.083-1.32z"
        />
      </defs>
      <use fill="#a9b4c9" fillRule="evenodd" xlinkHref="#i-16-exit_svg__a" />
    </svg>
  );
}

export default SvgI16ExitDark;
