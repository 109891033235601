import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";

import ProfileStore from "stores/Profile";

import SvgI48PlaceholderAvatar from "common/assets/icons/I48PlaceholderAvatar";

import { Container, LetterAvatar, Label, Icon, Error } from "./styles";

type Props = {};

@observer
class ProfileAvatar extends React.Component<Props> {
  @observable error: string = "";

  componentWillUnmount() {
    this.error = "";
  }

  onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    this.error = "";
    const formFile = event.target.files[0];
    if (formFile) {
      const { error } = await ProfileStore.editAvatar({ formFile });
      if (error) {
        this.error = error;
      }
    }
  };

  render() {
    return (
      <Container>
        <Label style={{ backgroundImage: `url(${ProfileStore.profilePhoto})` }}>
          {!ProfileStore.profilePhoto && (
            <LetterAvatar name={ProfileStore.name} size={162} fontSize={60} />
          )}
          <Icon>
            <SvgI48PlaceholderAvatar />
          </Icon>
          <input
            hidden
            type="file"
            accept=".bmp,.jpeg,.jpg,.png"
            onChange={this.onFileChange}
          />
        </Label>

        {this.error && <Error>{this.error}</Error>}
      </Container>
    );
  }
}

export default ProfileAvatar;
