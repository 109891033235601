import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;
export const TopBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 80px;
  width: 100%;
  box-shadow: 0 6px 16px 0 rgba(169, 180, 201, 0.31);
  background-color: #ebf0fb;
  z-index: 2;
`;

export const SideContainer = styled.div`
  margin: 0 32px;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex: 1;
`;

export const Content = styled.div`
  height: calc(100% - 80px);
  width: 100%;
  position: relative;
  z-index: 0;
`;
