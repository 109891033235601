import React from "react";

import { Container } from "./styles";

enum OutsideClickHandlerEvents {
  MOUSEUP = "mouseup",
  MOUSEDOWN = "mousedown",
}

type Props = {
  onClick: () => void;
  children: React.ReactNode;
  onMouseDown?: boolean;
};

class OutsideClickHandler extends React.Component<Props> {
  containerRef: React.RefObject<any> | null = React.createRef();

  get container() {
    return this.containerRef && this.containerRef.current;
  }

  get eventType() {
    const { onMouseDown } = this.props;
    return onMouseDown
      ? OutsideClickHandlerEvents.MOUSEDOWN
      : OutsideClickHandlerEvents.MOUSEUP;
  }

  componentDidMount(): void {
    document.addEventListener(this.eventType, this.onClick);
  }

  componentWillUnmount(): void {
    document.removeEventListener(this.eventType, this.onClick);
  }

  onClick = (event: MouseEvent) => {
    if (this.container && !this.container.contains(event.target)) {
      this.props.onClick();
    }
  };

  render() {
    const { children } = this.props;
    return <Container ref={this.containerRef}>{children}</Container>;
  }
}

export default OutsideClickHandler;
