import styled from "styled-components";

export const Horizontal = styled.div`
  width: 100%;
  height: 2px;

  background-color: #eef2f9;
`;

export const Vertical = styled.div`
  width: 2px;
  height: 100%;

  background-color: #eef2f9;
`;
