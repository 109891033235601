import { types } from "mobx-state-tree";

import { DictionaryItem } from "common/types/models";

export const DictionaryItemModel = types
  .model({
    id: types.number,
    name: types.maybe(types.string),
  })
  .views(self => ({
    get asDictionaryItem(): DictionaryItem {
      return { label: self.name || "", value: `${self.id}` };
    },
  }));
