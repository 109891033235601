import React from "react";
import { Portal } from "react-portal";

import SvgI24Exit from "common/assets/icons/I24Exit";

import { Container, Paper, ExitButton, Content } from "./styles";

type Props = {
  children: React.ReactNode;
  onClose: () => void;
  canCloseByOuterClick?: boolean;
};

class Popup extends React.Component<Props> {
  onClickOutside = () => {
    const { canCloseByOuterClick, onClose } = this.props;
    canCloseByOuterClick && onClose();
  };

  render() {
    const { children, onClose } = this.props;
    return (
      <Portal>
        <Container onClick={this.onClickOutside}>
          <Paper>
            <Content>
              <ExitButton icon={<SvgI24Exit />} onClick={onClose} />
              {children}
            </Content>
          </Paper>
        </Container>
      </Portal>
    );
  }
}

export default Popup;
