import React from "react";
import { FieldRenderProps } from "react-final-form";

import DropZone, { ExternalDropZoneProps } from "common/components/DropZone";

import withFieldControl from "../FieldControl";

type Props = {} & FieldRenderProps<any> & ExternalDropZoneProps;

class UploadFieldControl extends React.Component<Props> {
  addedfile = (file: File) => {
    const { input } = this.props;
    const event: any = {
      target: { value: file },
    };
    input.onChange(event);
  };

  removedFile = () => {
    const { input } = this.props;
    const event: any = {
      target: { value: undefined },
    };
    input.onChange(event);
  };

  render() {
    const { meta, ...props } = this.props;

    return (
      <DropZone
        componentConfig={{ postUrl: "no-url" }}
        eventHandlers={{
          addedfile: this.addedfile,
          removedfile: this.removedFile,
        }}
        error={meta.touched && meta.error}
        autoUpload={false}
        maxFiles={1}
        hideProgressBar
        addRemoveLinks
        fitWidth
        {...props}
      />
    );
  }
}

export default withFieldControl(UploadFieldControl);
