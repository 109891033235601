import React from "react";
import stringToIntHash from "string-hash";

import { Container } from "./styles";
import { EMPTY_NAME_PLACEHOLDER } from "common/utils";

const BACKGROUNDS = [
  "linear-gradient(to top, #5d43d5, #9163ee)",
  "linear-gradient(315deg, #5f7de6 88%, #5baff2 13%)",
  "linear-gradient(315deg, #22c099 88%, #71e198 13%)",
  "linear-gradient(to top, #9b53d1, #d866e4)",
  "linear-gradient(to bottom, #ffb612, #ff882f)",
  "linear-gradient(to bottom, #fc81bf, #f74a70)",
  "linear-gradient(324deg, #4a53df 88%, #5682f6 -17%)",
];

type Props = {
  name?: string;
  size: number;
  fontSize: number;
  className?: any;
  borderOnDarkBackground?: boolean;
};

class LetterAvatar extends React.Component<Props> {
  get name() {
    const { name } = this.props;
    return name || EMPTY_NAME_PLACEHOLDER;
  }

  get color() {
    return BACKGROUNDS[stringToIntHash(this.name) % BACKGROUNDS.length];
  }

  get shouldHasBorder() {
    return this.color === BACKGROUNDS[BACKGROUNDS.length - 1];
  }

  render() {
    const { size, fontSize, className, borderOnDarkBackground } = this.props;
    const [firstLetter] = this.name;
    return (
      <Container
        className={className}
        size={size}
        fontSize={fontSize}
        background={this.color}
        hasBorder={this.shouldHasBorder && borderOnDarkBackground}
      >
        {firstLetter && firstLetter.toUpperCase()}
      </Container>
    );
  }
}

export default LetterAvatar;
