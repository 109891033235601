import * as React from "react";

function SvgI16Renew2(props) {
  return (
    <svg width={16} height={16} {...props}>
      <path
        fill="#A9B4C9"
        fillRule="evenodd"
        d="M8 2a6 6 0 015.162 2.939l.159-.443a1 1 0 111.879.684L14.174 8a.984.984 0 01-.263.394l-.09.074c-.267.241-.654.33-1.015.2L9.987 7.64a1 1 0 11.684-1.879l.83.301A4.003 4.003 0 008 4C5.79 4 4 5.79 4 8a3.999 3.999 0 007.217 2.378 1 1 0 111.607 1.191 6.05 6.05 0 01-1.95 1.699A6 6 0 118 2z"
      />
    </svg>
  );
}

export default SvgI16Renew2;
