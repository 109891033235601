import React from "react";
import { Field, FieldRenderProps } from "react-final-form";

import { withFormContext, FormContextType } from "../formContext";

import { compose, emptyValidator } from "common/utils/validators";

type Props = {
  name: string;
  render: (props: FieldRenderProps<any>) => React.ReactNode;
  validate: (value: any) => boolean;
};

class FieldControl extends React.Component<Props & FormContextType> {
  componentDidMount(): void {
    const { name, registerField } = this.props;
    registerField && registerField({ name });
  }

  render() {
    const { name, validate, render } = this.props;
    return <Field name={name} render={render} validate={validate} />;
  }
}

const FieldControlWithContext = withFormContext(FieldControl);

const withFieldControl = (Wrapped: React.ClassType<any, any, any>) => {
  class Wrapper extends React.Component<any> {
    renderInput = (props: FieldRenderProps<any>) => {
      return <Wrapped {...this.props} {...props} />;
    };

    render() {
      const { name, validate, required } = this.props;
      return (
        <FieldControlWithContext
          name={name}
          render={this.renderInput}
          validate={
            required
              ? validate
                ? compose(emptyValidator, validate)
                : emptyValidator
              : validate
          }
        />
      );
    }
  }

  return Wrapper;
};

export default withFieldControl;
