import styled from "styled-components";

import { COLORS } from "common/constants/layout";

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 32px;
  border-radius: 6px;

  padding: 0 12px;

  border: none;
  outline: none;
  user-select: none;

  background-color: transparent;

  color: ${({ isGreyed }) =>
    isGreyed ? COLORS.TEXT_LIGHT : COLORS.CORNFLOWER};

  & path,
  & use {
    fill: ${({ isGreyed, disabled, disabledColor }) =>
      isGreyed || disabled
        ? disabledColor || COLORS.TEXT_LIGHT
        : COLORS.CORNFLOWER};
  }

  &:not(:disabled):hover {
    color: ${COLORS.CORNFLOWER};
    background-color: ${({ isGreyed }) =>
      isGreyed ? "transparent" : "rgba(86, 130, 246, 0.1)"};
  }

  &:not(:disabled):hover path,
  &:not(:disabled):hover use {
    fill: ${COLORS.CORNFLOWER};
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    color: ${({ disabledColor }) => disabledColor || COLORS.TEXT_LIGHT};

    &:hover path,
    &:hover use {
      fill: ${({ disabledColor }) => disabledColor || COLORS.TEXT_LIGHT};
    }
  }

  &:not(:disabled):active {
    background-color: transparent;
    color: ${COLORS.CORNFLOWER_DARK};

    & path,
    & use {
      fill: ${COLORS.CORNFLOWER_DARK};
    }
  }
`;

export const Text = styled.span`
  font-family: OpenSans-SemiBold;
  font-size: 14px;

  margin-right: ${({ isRight }) => (isRight ? "8px" : "0")};
  margin-left: ${({ isRight }) => (isRight ? "0" : "8px")};
`;

export const Icon = styled.div`
  margin-top: 6px;
`;
