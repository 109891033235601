import React from "react";

import { Container } from "./styles";

type Props = {
  children: React.ReactNode;
  withShadow?: boolean;
  className?: any;
};

function Paper({ children, withShadow, className }: Props) {
  return (
    <Container className={className} withShadow={withShadow}>
      {children}
    </Container>
  );
}

export default Paper;
