import styled from "styled-components";
import LetterAvatarDefault from "common/components/LetterAvatar";

export const Container = styled.div`
  margin-bottom: 24px;
`;

export const Icon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  display: none;

  transform: translate(-50%, -50%);

  z-index: 1;
`;

export const LetterAvatar = styled(LetterAvatarDefault)`
  margin: 0 auto;
`;

export const Label = styled.label`
  cursor: pointer;

  width: 162px;
  height: 162px;
  display: block;

  position: relative;

  margin: 0 auto;

  border-radius: 50%;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    ${Icon} {
      display: block;
    }

    &:after {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      border-radius: 50%;

      background-color: rgba(169, 180, 201, 0.7);
    }
  }
`;

export const Error = styled.p`
  font-size: 13px;
  font-family: "OpenSans-Regular";

  color: #f74a70;

  margin-top: 10px;

  text-align: center;
`;
