import React from "react";
import { ValueType } from "react-select";
import { MultiValueProps } from "react-select/src/components/MultiValue";
import { observer } from "mobx-react";
import { computed } from "mobx";
import { FieldInputProps } from "react-final-form";

import {
  Select,
  Container,
  Wrapper,
  Label,
  MultiValuePlaceholder,
  MultiValueContainer,
  MultiValue,
  MultiValueLabel,
  MultiValueClear,
  Error,
} from "./styles";

import { DictionaryItem } from "common/types/models";

import SvgI16ExitDark from "common/assets/icons/I16ExitDark";

type DropdownDataProvider = {
  options: Array<DictionaryItem>;
  isLoading: boolean;
};

export type DropdownExternalProps = {
  options?: Array<DictionaryItem>;
  disabled?: boolean;
  searchable?: boolean;
  multi?: boolean;
  loading?: boolean;
  placeholder?: string;
  provider?: DropdownDataProvider;
  value: DictionaryItem;
  label?: string;
  canStretchOnError?: boolean;
};

type Props = {
  onChange: (item: ValueType<DictionaryItem>) => void;
  error?: string;
  input: FieldInputProps<string>;
} & DropdownExternalProps;

const MultiValueContainerComp = (props: MultiValueProps<DictionaryItem>) => {
  return (
    <MultiValuePlaceholder>
      {props.selectProps.placeholder}
    </MultiValuePlaceholder>
  );
};

@observer
export default class Dropdown extends React.Component<Props> {
  @computed
  get values() {
    const { provider, options, input } = this.props;
    const currentOptions = provider?.options || options;
    const value = input?.value || [];
    return value.map(item =>
      currentOptions?.find(findItem => findItem.value === item),
    );
  }

  @computed
  get value() {
    const { provider, options, input } = this.props;
    const currentOptions = provider?.options || options;
    return currentOptions?.find(item => item.value === input?.value);
  }

  onChange = (value: ValueType<DictionaryItem>) => {
    this.props.onChange(value);
  };

  onRemoveItem = (value: ValueType<DictionaryItem>) => () => {
    const values = this.values.filter(item => item !== value);
    this.props.onChange(values);
  };

  render() {
    const {
      options,
      disabled,
      searchable,
      multi,
      loading,
      placeholder,
      error,
      input,
      label,
      provider,
      canStretchOnError,
    } = this.props;

    return (
      <Container>
        <Wrapper>
          <Select
            {...input}
            key={multi ? this.values : this.value}
            value={multi ? this.values : this.value}
            options={provider?.options || options}
            onChange={this.onChange}
            isDisabled={provider?.isLoading || loading || disabled}
            isSearchable={searchable}
            isMulti={multi}
            isLoading={provider?.isLoading || loading}
            placeholder={placeholder}
            error={error}
            menuPosition="fixed"
            components={{
              MultiValueContainer: MultiValueContainerComp,
            }}
          />
          {label && <Label>{label}</Label>}
        </Wrapper>
        {multi && this.values.length > 0 && (
          <MultiValueContainer>
            {this.values.map(item => (
              <MultiValue key={item?.value}>
                <MultiValueLabel>{item?.label}</MultiValueLabel>
                <MultiValueClear onClick={this.onRemoveItem(item)}>
                  <SvgI16ExitDark />
                </MultiValueClear>
              </MultiValue>
            ))}
          </MultiValueContainer>
        )}
        {error && <Error isStatic={canStretchOnError}>{error}</Error>}
      </Container>
    );
  }
}
