import React from "react";
import { FieldRenderProps } from "react-final-form";

import TextArea, {
  TextAreaExternalProps,
} from "common/components/Input/TextArea";
import { Field } from "common/components/Form";

import withFieldControl from "../FieldControl";

type Props = {
  title?: string;
  required?: boolean;
  action?: React.ReactNode;
} & TextAreaExternalProps;

function TextAreaFieldControl({
  title,
  required,
  action,
  input,
  meta,
  ...props
}: FieldRenderProps<any> & Props) {
  return (
    <Field title={title} action={action} required={required}>
      <TextArea input={input} error={meta.touched && meta.error} {...props} />
    </Field>
  );
}

export default withFieldControl(TextAreaFieldControl);
