import React from "react";

import { Container, GreetingTitle, HeaderTitle } from "./styles";

type Props = {
  name: string;
};

function Title({ name }: Props) {
  return (
    <Container>
      <GreetingTitle>Hi, {name}!</GreetingTitle>
      <HeaderTitle>Select where you’d Like to Login</HeaderTitle>
    </Container>
  );
}

export default Title;
