import styled from "styled-components";
import CheckBoxOrig from "common/components/Checkbox";

export const Container = styled.thead`
  background-color: #f6f9fe;
  border-radius: 6px 6px 0 0;
`;

export const CheckBox = styled(CheckBoxOrig)`
  margin-left: 0;
`;

export const TableHeaderContainer = styled.th`
  width: ${props => props.width ?? "unset"};
  margin: ${props => props.margin ?? 0};
  padding-left: ${props => props.paddingLeft ?? "36px"};
  padding-right: ${props => props.paddingRight ?? "36px"};
  user-select: none;

  :first-child {
    border-radius: 6px 0 0 0;
  }

  :last-child {
    border-radius: 0 6px 0 0;
  }
`;

export const DataContainer = styled.div`
  max-height: 51px;
  min-height: 51px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const Label = styled.p`
  font-family: OpenSans-SemiBold;
  font-size: 14px;

  color: #a9b4c9;
  white-space: nowrap;
`;
