import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Button, Title, Counter } from "./styles";

type Props = {
  icon: React.ReactNode;
  title: string;
  route: string;
  disabled?: boolean;
  counter?: number;
} & RouteComponentProps;

class NavBarButton extends React.Component<Props> {
  get isActive() {
    const { location, route } = this.props;
    return location.pathname.toUpperCase().startsWith(route.toUpperCase());
  }

  onClick = () => {
    const { history, route, disabled } = this.props;
    if (disabled) return;
    history.push(route);
  };

  render() {
    const { icon, title, counter } = this.props;
    return (
      <Button isActive={this.isActive} onClick={this.onClick}>
        {icon}
        <Title>{title}</Title>
        {counter && counter > 0 ? <Counter>{counter}</Counter> : null}
      </Button>
    );
  }
}

export default withRouter(NavBarButton);
