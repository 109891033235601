import React from "react";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";

import { Row, Field } from "common/components/Form";
import TextInput from "common/components/Input/TextInput";

import { RegisterButton, DoubleRow, LastRow, ErrorText } from "./styles";
import { phoneNumberValidator } from "common/utils/validators";

import { RegisterData } from "common/types/models";

import { MASKS } from "common/constants/forms";
import { CheckBox } from "common/components/Table/TableHeader.styles";

type Props = {
  onSubmit: (data: RegisterData) => void;
  serverError: string;
  isLoading?: boolean;
  userId: number;
  code: string;
};

@observer
class RegisterForm extends React.Component<Props> {
  @observable firstName: string = "";
  @observable lastName: string = "";
  @observable phoneNumber: string = "";
  @observable password: string = "";
  @observable confirmPassword: string = "";

  @observable agreedLicense: boolean = false;

  @observable passwordError: string = "";
  @observable phoneError: string = "";

  @computed get canRegister(): boolean {
    return (
      !!this.firstName &&
      !!this.lastName &&
      !!this.password &&
      !!this.confirmPassword &&
      this.agreedLicense
    );
  }

  resetErrors = () => {
    this.passwordError = "";
    this.phoneError = "";
  };

  onChangeFirstName = (value: string) => {
    this.firstName = value.trim();
    this.resetErrors();
  };

  onChangeLastName = (value: string) => {
    this.lastName = value.trim();
    this.resetErrors();
  };

  onChangePhoneNumber = (value: string) => {
    this.phoneNumber = value.trim();
    this.resetErrors();
  };

  onChangePassword = (value: string) => {
    this.password = value.trim();
    this.resetErrors();
  };

  onChangeConfirmPassword = (value: string) => {
    this.confirmPassword = value.trim();
    this.resetErrors();
  };

  onAgreedLicenseClick = () => {
    this.agreedLicense = !this.agreedLicense;
  };

  validate = () => {
    if (this.password !== this.confirmPassword) {
      this.passwordError = "Password and confirmation password do not match";
      return false;
    }

    if (this.password?.length < 8) {
      this.passwordError =
        "Set Minimum password length to at least a value of 8";
      return false;
    }

    if (this.phoneNumber?.indexOf(MASKS.PHONE.maskChar) > 0) {
      this.phoneError = "Please enter a valid phone number";
      return false;
    }

    return true;
  };

  onSubmit = (e: any) => {
    e.preventDefault();
    const { onSubmit, userId, code } = this.props;

    if (!this.validate()) {
      return;
    }

    onSubmit({
      code: code,
      userId: userId,
      firstName: this.firstName,
      lastName: this.lastName,
      phoneNumber: this.phoneNumber,
      password: this.password,
    });
  };

  render() {
    const { serverError, isLoading } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        <DoubleRow>
          <Row>
            <Field title="First name" required>
              <TextInput
                value={this.firstName}
                onChange={this.onChangeFirstName}
                type="text"
                autoComplete="first-name"
                canStretchOnError={true}
              />
            </Field>
          </Row>

          <Row>
            <Field title="Last name" required>
              <TextInput
                value={this.lastName}
                onChange={this.onChangeLastName}
                type="text"
                autoComplete="last-name"
                canStretchOnError={true}
              />
            </Field>
          </Row>
        </DoubleRow>

        <Row>
          <Field title="Phone number">
            <TextInput
              value={this.phoneNumber}
              onChange={this.onChangePhoneNumber}
              type="text"
              autoComplete="phone-number"
              mask={MASKS.PHONE}
              validate={phoneNumberValidator}
              placeholder="(XXX)-XXX-XXXX"
              canStretchOnError={true}
              error={this.phoneError}
            />
          </Field>
        </Row>

        <Row>
          <Field title="Password" required>
            <TextInput
              value={this.password}
              onChange={this.onChangePassword}
              type="password"
              canStretchOnError={true}
            />
          </Field>
        </Row>

        <Row>
          <Field title="Confirm password" required>
            <TextInput
              value={this.confirmPassword}
              onChange={this.onChangeConfirmPassword}
              type="password"
              error={this.passwordError}
              canStretchOnError={true}
            />
          </Field>
        </Row>

        {serverError && (
          <Row>
            <ErrorText>{serverError}</ErrorText>
          </Row>
        )}

        <LastRow>
          <CheckBox
            isChecked={this.agreedLicense}
            onClick={this.onAgreedLicenseClick}
            label={
              "I have read and agree to the Terms and Conditions and Privacy Policy."
            }
          />

          <RegisterButton
            type="submit"
            disabled={!this.canRegister}
            isLoading={isLoading}
          >
            Register
          </RegisterButton>
        </LastRow>
      </form>
    );
  }
}

export default RegisterForm;
