import React from "react";
import { Button, Counter } from "./styles";
import Icon from "common/components/Icon";

type Props = {
  icon?: React.ReactNode;
  onClick?: () => void;
  shouldStopPropagation?: boolean;
  className?: any;
  color?: string;
  hoverColor?: string;
  counter?: number;
};

function Fab({
  icon,
  onClick,
  shouldStopPropagation,
  className,
  color,
  hoverColor,
  counter,
}: Props): React.ReactElement {
  const onClickHandle = (e: { [key: string]: any }) => {
    if (shouldStopPropagation) {
      e.stopPropagation();
    }
    onClick && onClick();
  };

  return (
    <Button className={className} onClick={onClickHandle}>
      <Icon color={color} hoverColor={hoverColor} icon={icon} />
      {counter && counter > 0 ? <Counter>{counter}</Counter> : null}
    </Button>
  );
}

export default Fab;
