import React from "react";

import SvgI16Check from "common/assets/icons/I16Check";

import { Container, Border, Label } from "./styles";

export type CheckboxExternalProps = {
  isChecked: boolean;
  onClick: () => void;
  label?: string;
  className?: string;
  accent?: boolean;
};

type Props = {} & CheckboxExternalProps;

class Checkbox extends React.Component<Props> {
  render() {
    const { className, isChecked, onClick, label, accent } = this.props;
    return (
      <Container className={className} onClick={onClick}>
        <Border isChecked={isChecked}>{isChecked && <SvgI16Check />}</Border>
        {label && <Label accent={accent}>{label}</Label>}
      </Container>
    );
  }
}

export default Checkbox;
