import * as React from "react";

function SvgI16Exit(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" {...props}>
      <path
        fill="#5682F6"
        d="M2.293 2.293a1 1 0 011.414 0L8 6.585l4.293-4.292a1 1 0 011.414 1.414L9.415 8l4.292 4.293a1 1 0 01.083 1.32l-.083.094a1 1 0 01-1.414 0L8 9.415l-4.293 4.292a1 1 0 11-1.414-1.414L6.585 8 2.293 3.707a1 1 0 01-.083-1.32z"
      />
    </svg>
  );
}

export default SvgI16Exit;
