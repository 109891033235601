import React from "react";

import { Container, Marker, Text } from "./styles";

export type RadioButtonExternalProps = {
  label: string;
  isSelected: boolean;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
};

type Props = {} & RadioButtonExternalProps;

class RadioButton extends React.Component<Props> {
  render() {
    const { className, isSelected, label, onClick } = this.props;
    return (
      <Container className={className} onClick={onClick}>
        <Marker isSelected={isSelected} />
        <Text isSelected={isSelected}>{label}</Text>
      </Container>
    );
  }
}

export default RadioButton;
