import React from "react";

import { Container } from "./styles";

type Props = {
  icon: React.ReactNode;
  className?: any;
  color?: string;
  hoverColor?: string;
};

class Icon extends React.Component<Props> {
  render() {
    const { icon, color, hoverColor, className } = this.props;
    return (
      <Container classname={className} color={color} hoverColor={hoverColor}>
        {icon}
      </Container>
    );
  }
}

export default Icon;
