import React from "react";
import { FieldRenderProps } from "react-final-form";

import TextInput, {
  TextInputExternalProps,
} from "common/components/Input/TextInput";
import { Field } from "common/components/Form";

import withFieldControl from "../FieldControl";

type Props = {
  title?: string;
  required?: boolean;
} & TextInputExternalProps;

function TextFieldControl({
  title,
  required,
  input,
  meta,
  ...props
}: FieldRenderProps<any> & Props) {
  return (
    <Field title={title} required={required}>
      <TextInput input={input} error={meta.touched && meta.error} {...props} />
    </Field>
  );
}

export default withFieldControl(TextFieldControl);
