import React from "react";

import {
  TitleContainer,
  LeftContent,
  RightContent,
  TitleText,
  Badge,
} from "./Title.styles";

type Props = {
  title: string;
  badge?: React.ReactNode;
  controls?: React.ReactNode;
  leftPanel?: React.ReactNode;
  className?: any;
};

export default function Title({ title, badge, controls, className }: Props) {
  return (
    <TitleContainer className={className}>
      <LeftContent>
        <TitleText>{title}</TitleText>
        {badge && <Badge>{badge}</Badge>}
      </LeftContent>
      {controls && <RightContent>{controls}</RightContent>}
    </TitleContainer>
  );
}
