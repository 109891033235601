import { RegisterData } from "./../common/types/models";
import api, { assignAuth, assignOnRefresh } from "common/services/api";
import { flow, types, onSnapshot } from "mobx-state-tree";
import { AuthModel } from "models/Auth";
import { ROLES } from "constants/roles";

const AuthStore = types
  .model("AuthStore", {
    tokenData: types.maybe(AuthModel),
    dashboard: types.maybe(
      types.enumeration("Dashboard", [
        "LoginDashboard",
        "SuperAdminDashboard",
        "CustomerAdminDashboard",
        "StudyAdminDashboard",
      ]),
    ),
    /**
     * Determines if the network request is in a loading state or not.
     */
    isLoading: types.maybe(types.boolean),
    authRole: types.maybe(
      types.enumeration("AuthRole", [
        "NONE",
        "CUSTOMER_ADMIN",
        "STUDY_ADMIN",
        "SUPER_ADMIN",
      ]),
    ),
  })
  .actions(self => ({
    signOut: () => {
      window.localStorage.removeItem("tokenData");
      self.tokenData = undefined;
      window.localStorage.removeItem("dashboard");
      self.dashboard = undefined;
      window.localStorage.removeItem("authRole");
      self.authRole = undefined;
    },
  }))
  .actions(self => ({
    refreshToken: flow(function*() {
      try {
        self.isLoading = true;
        const result = yield api.post("/account/refresh", {
          token: auth.tokenData?.refreshToken,
        });
        if (result.accessToken) {
          self.tokenData = result;
        }
        return result;
      } catch (error) {
        console.error("error", error);
        self.signOut();
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
  }))
  .actions(self => ({
    afterCreate() {
      const tokenData = window.localStorage.getItem("tokenData");
      assignOnRefresh(self.refreshToken);
      if (tokenData) {
        const authObject = JSON.parse(tokenData);
        self.tokenData = authObject;
        assignAuth(authObject);
      }
      const dashboard = window.localStorage.getItem("dashboard");
      if (dashboard) {
        self.dashboard = dashboard;
      }
      const authRole = window.localStorage.getItem("authRole");
      if (authRole) {
        self.authRole = authRole;
      }
      onSnapshot(self, snapshot => {
        assignAuth(snapshot.tokenData);
        assignOnRefresh(self.refreshToken);
        if (snapshot.tokenData) {
          window.localStorage.setItem(
            "tokenData",
            JSON.stringify(snapshot.tokenData),
          );
        }
        if (snapshot.dashboard) {
          window.localStorage.setItem("dashboard", snapshot.dashboard);
        }
        if (snapshot.authRole) {
          window.localStorage.setItem("authRole", snapshot.authRole);
        }
      });
    },
    signIn: flow(function*(email: string, password: string) {
      try {
        self.isLoading = true;
        const result = yield api.post("/account/login", {
          email: email,
          password: password,
        });
        if (result.tokenData?.accessToken) {
          self.tokenData = result.tokenData;
        }
        if (result.dashboard) {
          self.dashboard = result.dashboard;
          switch (result.dashboard) {
            case "LoginDashboard":
              self.authRole = "NONE";
              break;
            case "SuperAdminDashboard":
              self.authRole = "SUPER_ADMIN";
              break;
            case "CustomerAdminDashboard":
              self.authRole = "CUSTOMER_ADMIN";
              break;
            case "StudyAdminDashboard":
              self.authRole = "STUDY_ADMIN";
              break;
          }
        }
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    assignRole: flow(function*(
      roleId: number,
      customerId?: number,
      studyId?: number,
      siteId?: number,
    ) {
      try {
        self.isLoading = true;

        const payload = {
          roleId,
          ...{ customerId },
          ...{ studyId },
          ...{ siteId },
        };
        const result = yield api.post("/account/assign-role", payload);

        if (result.accessToken) {
          self.tokenData = result;
          // TODO: add additional roles
          switch (roleId) {
            case ROLES.SUPER_ADMIN:
              self.authRole = "SUPER_ADMIN";
              break;
            case ROLES.CUSTOMER_ADMIN:
              self.authRole = "CUSTOMER_ADMIN";
              break;
            case ROLES.STUDY_ADMIN:
              self.authRole = "STUDY_ADMIN";
          }
        }
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    register: flow(function*(data: RegisterData) {
      try {
        self.isLoading = true;
        const result = yield api.post("/account/register", data);
        if (result.tokenData?.accessToken) {
          self.tokenData = result.tokenData;
        }
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
  }))
  .views(self => ({
    get isSignedIn() {
      return !!self.tokenData?.accessToken;
    },
  }));

export const auth = AuthStore.create();
