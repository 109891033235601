import styled from "styled-components";
import DefaultSelect from "react-select";

const selectStyles = {
  control: (provided, props) => ({
    ...provided,
    height: "100%",
    borderWidth: "2px",
    borderRadius: "7px",
    borderColor: props.selectProps.error ? "#f74a70" : "#d8ddeb",
  }),
  placeholder: provided => ({
    ...provided,
    color: "rgba(51,51,51,0.4)",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  valueContainer: provided => ({
    ...provided,
    padding: "0 16px",
  }),
  input: provided => ({
    ...provided,
    fontFamily: "OpenSans-Regular",
    fontSize: "16px",
  }),
};

export const Select = styled(DefaultSelect).attrs({ styles: selectStyles })`
  width: 100%;
  height: 48px;

  & > div {
    height: 100%;
  }

  & input::placeholder {
    color: rgba(51, 51, 51, 0.4);
  }
`;

export const Container = styled.div`
  width: 100%;

  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  font-size: 14px;
  color: #919bb8;
  margin-left: 8px;
  white-space: nowrap;
`;

export const MultiValuePlaceholder = styled.div`
  color: rgba(51, 51, 51, 0.4);
  margin-left: 2px;
  margin-right: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;

  &:not(:first-child) {
    display: none;
  }
`;

export const MultiValueContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const MultiValue = styled.div`
  border-radius: 6px;
  padding: 6px 8px 7px 12px;
  background-color: rgba(86, 130, 246, 0.1);

  display: flex;
  align-items: center;

  margin-right: 12px;
  margin-top: 12px;

  z-index: 1;
`;

export const MultiValueLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #37383d;
`;

export const MultiValueClear = styled.span`
  display: flex;
  margin-left: 8px;

  cursor: pointer;
`;

export const Error = styled.p`
  position: ${({ isStatic }) => (isStatic ? "static" : "absolute")};

  font-size: 13px;
  font-family: "OpenSans-Regular";

  color: #f74a70;

  margin-top: 4px;
`;
