import styled from "styled-components";
import styledWrapper from "common/components/StyledWrapper";

import FormRow from "common/components/Form/Row";
import { ButtonOutline } from "common/components/Button";
import SubmitButtonControl from "common/components/FormControl/SubmitButtonControl";

export const Container = styled.div`
  margin-bottom: 23px;
`;

export const Row = styledWrapper(FormRow)`
  padding-bottom: 10px;
  width: 442px;
`;

export const Cell = styled.div`
  flex: 1;

  &:not(:first-child) {
    margin-left: 24px;
  }
`;

export const Buttons = styled.div`
  display: flex;
`;

export const ButtonBack = styledWrapper(ButtonOutline)`
  width: 120px;
  margin-right: 20px;
`;

export const ButtonSubmit = styledWrapper(SubmitButtonControl)`
  width: 120px;
`;
