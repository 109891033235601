import * as React from "react";

function SvgI16Logout(props) {
  return (
    <svg width={16} height={16} {...props}>
      <path
        fill="#A9B4C9"
        fillRule="evenodd"
        d="M8.003 1.007a1 1 0 010 2h-5.01v10h5.01a1 1 0 010 2h-6.01a1 1 0 01-1-1v-12a1 1 0 011-1h6.01zM11.64 4.22l.094.083 2.99 2.966.097.112.072.111.053.113.035.105.025.118.01.094-.002.13-.017.125-.03.111-.043.111-.053.098-.073.105-.074.081-2.99 3.027a1 1 0 01-1.497-1.32l.083-.094 1.298-1.32h-4.6a1 1 0 110-2h4.575L10.32 5.717a1 1 0 01-.083-1.32l.083-.094a1 1 0 011.32-.083z"
      />
    </svg>
  );
}

export default SvgI16Logout;
