import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";

import ROUTES from "navigation/routes";

import { ROLES } from "constants/roles";

import Background from "common/components/Layout/Background/BackgroundLight";
import { Header } from "common/components/Layout";
import NavPane from "common/components/Layout/NavPane";

import { auth } from "stores/Auth";
import ProfileStore from "stores/Profile";
import AuthDashboardStore from "stores/AuthDashboard";

import { Container, LoginButton, Title, Selector, Loader } from "./styles";
import { SelectPayload } from "./Selector";

import withGlobalPopup, {
  GlobalPopupProps,
} from "common/components/Popup/withGlobalPopup";
import ConfirmationDialog from "common/components/ConfirmationDialog";

type Props = {} & RouteComponentProps & GlobalPopupProps;

@observer
class LoginByRole extends React.Component<Props> {
  async componentDidMount() {
    await Promise.all([ProfileStore.fetch(), AuthDashboardStore.fetch()]);
  }

  @observable selected: SelectPayload | null = null;

  @computed
  get isLoginEnabled() {
    return !!this.selected;
  }

  onSelect = (selected: SelectPayload) => {
    this.selected = selected;
  };

  assignRole = async (data: SelectPayload) => {
    let success = false;
    let error = "";
    let errorCode = "";

    try {
      await auth.assignRole(
        data.roleId,
        data.customerId,
        data.studyId,
        data.siteId,
      );
      success = true;
    } catch (e) {
      error = e.message;
      errorCode = e.name;
      console.error("error", e);
      success = false;
    }

    return { error, errorCode, success };
  };

  showExpiredStudyAlert = ({
    title,
    text,
  }: {
    title: string;
    text: string;
  }) => {
    const { openPopup } = this.props;

    openPopup({
      key: "ExpiredStudyAlert",
      canCloseByOuterClick: true,
      render: ({ close }) => (
        <ConfirmationDialog
          title={title}
          text={text}
          acceptText={"Ok"}
          onAccept={close}
        />
      ),
    });
  };

  onLogin = async () => {
    if (!this.selected) return;

    const { success, errorCode } = await this.assignRole(this.selected);

    if (errorCode === "study_expired_for_customer_user") {
      this.showExpiredStudyAlert({
        title: "Study expired",
        text: `This study has expired. Please contact the administrator for more information.`,
      });
      return;
    }

    if (errorCode === "study_not_active_for_customer_user") {
      this.showExpiredStudyAlert({
        title: "Study isn't active",
        text: `This study isn't active. Please contact the administrator for more information.`,
      });
      return;
    }

    if (success) {
      switch (this.selected.roleId) {
        case ROLES.SUPER_ADMIN:
          this.props.history.push(ROUTES.SUPER_ADMIN);
          break;
        case ROLES.CUSTOMER_ADMIN:
          this.props.history.push(ROUTES.CUSTOMER_ADMIN);
          break;
        case ROLES.STUDY_ADMIN:
          this.props.history.push(ROUTES.STUDY_ADMIN);
          break;
      }
    }
  };

  render() {
    return (
      <Container>
        <Background />
        <Header />
        {ProfileStore.isLoading || AuthDashboardStore.isLoading ? (
          <Loader />
        ) : (
          <>
            <NavPane
              rightContent={
                <LoginButton
                  onClick={this.onLogin}
                  disabled={!this.isLoginEnabled}
                >
                  Login
                </LoginButton>
              }
            />
            <Title name={ProfileStore.name} />
            <Selector onSelect={this.onSelect} />
          </>
        )}
      </Container>
    );
  }
}

export default withGlobalPopup(LoginByRole);
