import styled from "styled-components";

import { COLORS } from "common/constants/layout";

export const Container = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
`;

export const Label = styled.p`
  font-family: OpenSans;
  font-size: 14px;
  color: ${({ accent }) => (accent ? "#37383d" : "#919bb8")};

  margin-left: 10px;

  user-select: none;
`;

export const Border = styled.div`
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;

  border-radius: 4px;
  border: solid 1px ${COLORS.TEXT_LIGHT};
  background-color: ${props => (props.isChecked ? "#5682f6" : "transparent")};

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${props => (props.isChecked ? "#4a53df" : "#dce3f5")};
  }
`;
