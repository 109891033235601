import styled from "styled-components";

export const Columns = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Column = styled.div`
  flex: 1;
  min-width: 384px;

  padding: 24px;

  border-radius: 6px;
  background-color: #f8fafe;

  &:not(:last-of-type) {
    margin-right: 23px;
  }
`;

export const ColumnHeader = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 20px;
`;

export const IconContainer = styled.div`
  width: 36px;
  height: 36px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

export const HeaderTitle = styled.div`
  font-family: OpenSans-Bold;
  font-size: 18px;

  color: #37383d;
  opacity: 0.76;

  margin-left: 14px;
`;

export const SmartLogo = styled.div`
  margin-top: 8px;
`;
