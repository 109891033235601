import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  position: relative;
`;

export const Area = styled.textarea`
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : "123px")};

  padding: 8px 16px;

  color: #333333;
  background-color: ${props => (props.disabled ? "#f0f2f7" : "transparent")};

  font-family: OpenSans-Regular;
  font-size: 16px;

  outline: none;

  border-radius: 7px;
  border: ${props =>
    props.hasError ? "solid 2px #f74a70;" : "solid 2px #d8ddeb"};

  &::placeholder {
    color: rgba(51, 51, 51, 0.4);
  }

  &:active {
    border: ${props =>
      props.hasError ? "solid 2px #f74a70;" : "solid 2px #5682f6"};
  }

  &:focus {
    border: ${props =>
      props.hasError ? "solid 2px #f74a70;" : "solid 2px #5682f6"};
  }
`;

export const Error = styled.p`
  position: ${({ isStatic }) => (isStatic ? "static" : "absolute")};

  font-size: 13px;

  color: #f74a70;

  margin-top: 4px;
`;
