import { flow, types, applySnapshot } from "mobx-state-tree";

import api from "common/services/api";

import { AuthDashboardModel } from "models/Auth";

const AuthDashboardStore = types
  .model({
    data: types.optional(AuthDashboardModel, {}),
    isLoading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    fetch: flow(function*() {
      self.isLoading = true;
      try {
        const payload = yield api.get("/login-dashboard");
        applySnapshot(self.data, payload);
      } finally {
        self.isLoading = false;
      }
    }),
  }));

export default AuthDashboardStore.create();
