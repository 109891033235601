import React from "react";
import {
  Switch as SwitchContext,
  Redirect,
  Route,
  RouteProps,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import { observer } from "mobx-react";

import ROUTES from "./routes";

import Loadable from "common/components/Loadable";

import Login from "common/pages/Login";
import Register from "common/pages/Register";
import LoginByRole from "common/pages/LoginByRole";

import { AdminLoaderLayout } from "./LazyLoad";
import GlobalNavigationContext from "./context";

import { auth } from "stores/Auth";

const SuperAdmin = Loadable(() => import("superAdmin/entry"), {
  LoaderLayout: AdminLoaderLayout,
});

const CustomerAdmin = Loadable(() => import("customerAdmin/entry"), {
  LoaderLayout: AdminLoaderLayout,
});

const StudyAdmin = Loadable(() => import("studyAdmin/entry"), {
  LoaderLayout: AdminLoaderLayout,
});

type AuthRole = "NONE" | "CUSTOMER_ADMIN" | "STUDY_ADMIN" | "SUPER_ADMIN";

interface PrivateRouteProps extends RouteProps {
  authRoles: [AuthRole];
  redirectPath: string;
}

const PrivateRoute = ({
  authRoles,
  redirectPath,
  ...rest
}: PrivateRouteProps) => {
  return authRoles.includes(auth.authRole) ? (
    <Route {...rest} />
  ) : (
    <Redirect to={redirectPath} />
  );
};

@observer
class Switch extends React.Component<RouteComponentProps> {
  get navigationContext() {
    return {
      navigateToLogin: this.navigateToLogin,
      navigateToLoginByRole: this.navigateToLoginByRole,
    };
  }

  navigateToLogin = () => {
    this.props.history.push(ROUTES.LOGIN);
  };

  navigateToLoginByRole = () => {
    this.props.history.push(ROUTES.LOGIN_BY_ROLE);
  };

  getRouteByRole = (currentRole: string) => {
    switch (currentRole) {
      case "SUPER_ADMIN":
        return ROUTES.SUPER_ADMIN;
      case "CUSTOMER_ADMIN":
        return ROUTES.CUSTOMER_ADMIN;
      case "STUDY_ADMIN":
        return ROUTES.STUDY_ADMIN;
      case "NONE":
        return ROUTES.LOGIN_BY_ROLE;
      default:
        return ROUTES.LOGIN;
    }
  };

  render() {
    return (
      <GlobalNavigationContext.Provider value={this.navigationContext}>
        <SwitchContext>
          <Route exact path={ROUTES.LOGIN} component={Login} />
          <Route exact path={ROUTES.REGISTER} component={Register} />

          <PrivateRoute
            exact
            path={ROUTES.LOGIN_BY_ROLE}
            component={LoginByRole}
            authRoles={["NONE", "SUPER_ADMIN", "CUSTOMER_ADMIN", "STUDY_ADMIN"]}
            redirectPath={ROUTES.LOGIN}
          />
          <PrivateRoute
            path={ROUTES.SUPER_ADMIN}
            component={SuperAdmin}
            authRoles={["SUPER_ADMIN"]}
            redirectPath={ROUTES.LOGIN_BY_ROLE}
          />
          <PrivateRoute
            path={ROUTES.CUSTOMER_ADMIN}
            component={CustomerAdmin}
            authRoles={["CUSTOMER_ADMIN"]}
            redirectPath={ROUTES.LOGIN_BY_ROLE}
          />
          <PrivateRoute
            path={ROUTES.STUDY_ADMIN}
            component={StudyAdmin}
            authRoles={["STUDY_ADMIN"]}
            redirectPath={ROUTES.LOGIN_BY_ROLE}
          />

          <Redirect to={this.getRouteByRole(auth.authRole)} />
        </SwitchContext>
      </GlobalNavigationContext.Provider>
    );
  }
}

export default withRouter(Switch);
