import styled from "styled-components";

import styledWrapper from "common/components/StyledWrapper";
import { ButtonBlue } from "common/components/Button";
import DefaultTitle from "./Title";
import DefaultSelector from "./Selector";
import DefaultLoader from "common/components/Loader";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 1198px;
  margin: 0 auto;

  padding: 0 23px;
`;

export const LoginButton = styledWrapper(ButtonBlue)`
  width: 120px;
  margin-right: 184px;
`;

export const Title = styledWrapper(DefaultTitle)`
  margin-top: 30px;
`;

export const Selector = styledWrapper(DefaultSelector)`
  margin-top: 38px;
  
  flex: 1;
  
  margin-bottom: 30px;
`;

export const Loader = styledWrapper(DefaultLoader)`
  height: calc(100vh - 100px);
`;
