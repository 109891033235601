import styledWrapper from "common/components/StyledWrapper";
import styled from "styled-components";

import Link from "common/components/Link";
import { ButtonBlue } from "common/components/Button";
export { COLORS } from "common/constants/layout";

export const ForgotPasswordLink = styledWrapper(Link)`
  margin-top: 7px;
`;

export const RegisterButton = styledWrapper(ButtonBlue)`
`;

export const DoubleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > * {
    margin-right: 22px;
    flex: 1;
  }

  & > :last-child {
    margin-right: 0px;
  }
`;

export const LastRow = styled(DoubleRow)`
  margin-top: 60px;
`;

export const ErrorText = styled.p`
  font-size: 13px;
  font-family: "OpenSans-Regular";

  color: #f74a70;
`;
