import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { observable } from "mobx";
import { observer } from "mobx-react";

import ROUTES from "navigation/routes";

import Background from "common/components/Layout/Background/BackgroundBlue";
import Paper from "common/components/Paper";

import LoginForm from "./LoginForm";

import { Container, Content, Logo, Separator } from "./styles";

import { AuthData } from "common/types/models";

import { auth } from "stores/Auth";

type Props = {} & RouteComponentProps;

@observer
class Login extends React.Component<Props> {
  @observable serverError: string = "";

  signIn = async (login: string, password: string) => {
    this.serverError = "";
    try {
      await auth.signIn(login, password);
      return true;
    } catch (error) {
      this.serverError = error.message;
      return false;
    }
  };

  navigateToNextScreen = () => {
    switch (auth.dashboard) {
      case "LoginDashboard":
        this.props.history.push(ROUTES.LOGIN_BY_ROLE);
        break;
      case "SuperAdminDashboard":
        this.props.history.push(ROUTES.SUPER_ADMIN);
        break;
      case "CustomerAdminDashboard":
        this.props.history.push(ROUTES.CUSTOMER_ADMIN);
        break;
      case "StudyAdminDashboard":
        this.props.history.push(ROUTES.STUDY_ADMIN);
        break;
    }
  };

  onSubmit = async ({ login, password }: AuthData) => {
    if (await this.signIn(login, password)) {
      this.navigateToNextScreen();
    }
  };

  render() {
    return (
      <Container>
        <Background />
        <Paper>
          <Content>
            <Logo />
            <Separator />
            <LoginForm
              onSubmit={this.onSubmit}
              serverError={this.serverError}
              isLoading={auth.isLoading}
            />
          </Content>
        </Paper>
      </Container>
    );
  }
}

export default withRouter(Login);
