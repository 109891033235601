import React from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";
import {
  ActionMeta,
  ValueType,
  ValueContainerProps,
  components,
  OptionProps,
} from "react-select";
import { FieldInputProps } from "react-final-form";

import { Select, Container, Label, Checkbox, Error } from "./styles";

import { DictionaryItem } from "common/types/models";

type DropdownDataProvider = {
  options: Array<DictionaryItem>;
  isLoading: boolean;
};

export type CheckboxesDropdownExternalProps = {
  options?: Array<DictionaryItem>;
  disabled?: boolean;
  loading?: boolean;
  placeholder?: string;
  provider?: DropdownDataProvider;
  value: DictionaryItem;
};

type Props = {
  onChange: (item: ValueType<DictionaryItem>) => void;
  error?: string;
  input: FieldInputProps<string>;
} & CheckboxesDropdownExternalProps;

const Value = () => null;

const ValueContainer = (props: ValueContainerProps<DictionaryItem>) => {
  const value = props.getValue();
  const optionsLabel = value?.map(item => item.label).join(", ");

  return (
    <components.ValueContainer {...props}>
      <Label>{optionsLabel}</Label>
      {props.children}
    </components.ValueContainer>
  );
};

const Option = (props: OptionProps<DictionaryItem>) => {
  return (
    <components.Option {...props}>
      <Checkbox
        label={props.label}
        isChecked={props.isSelected}
        onClick={() => null}
      />
    </components.Option>
  );
};

@observer
export default class CheckboxesDropdown extends React.Component<Props> {
  @computed
  get values() {
    const { provider, options, input } = this.props;
    const currentOptions = provider?.options || options;
    const value = input?.value || [];
    return value.map(item =>
      currentOptions?.find(findItem => findItem.value === item),
    );
  }

  onChange = (value: ValueType<DictionaryItem>, actionMeta: ActionMeta) => {
    this.props.onChange(value);
  };

  render() {
    const {
      options,
      disabled,
      loading,
      placeholder,
      error,
      input,
      provider,
    } = this.props;

    return (
      <Container>
        <Select
          {...input}
          value={this.values}
          options={provider?.options || options}
          onChange={this.onChange}
          isDisabled={disabled}
          isLoading={provider?.isLoading || loading}
          placeholder={placeholder}
          isMulti={true}
          isClearable={false}
          isSearchable={false}
          hideSelectedOptions={false}
          error={error}
          menuPosition="fixed"
          components={{
            MultiValue: Value,
            ValueContainer,
            Option,
          }}
        />
        {error && <Error>{error}</Error>}
      </Container>
    );
  }
}
