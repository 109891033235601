import styled from "styled-components";

import styledWrapper from "common/components/StyledWrapper";

import Fab from "common/components/Button/Fab";
import DefaultPaper from "common/components/Paper";
import OutsideClickHandler from "../OutsideClickHandler";

export const Container = styledWrapper(OutsideClickHandler)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;

  width: 100%;
  height: 100%;

  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px;
`;

export const ExitButton = styledWrapper(Fab)`
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const Content = styled.div`
  padding: 50px 60px 0;

  max-height: 100%;
  overflow-y: auto;
  overflow-y: overlay;

  align-self: center;

  position: relative;

  /* Padding bottom hack for EDGE */
  &:after {
    content: "";
    display: block;
    height: 60px;
    width: 100%;
  }
`;

export const Paper = styledWrapper(DefaultPaper)`
  display: flex;
  height: 100%;
`;
