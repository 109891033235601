import React from "react";

import { Container } from "./styles";

const styledWrapper = (Component: React.ClassType<any, any, any>) => (
  ...styling: any
) => (props: any) => (
  <Container styling={styling}>
    <Component {...props} />
  </Container>
);

export default styledWrapper;
