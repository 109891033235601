import React from "react";

import { withFormContext, FormContextType } from "../formContext";

type ButtonProps = {
  onClick: () => void;
};

type Props = {
  render: (props: ButtonProps) => React.ReactNode;
  onClick?: () => void;
  canSubmit?: boolean;
};

function onDummyClick() {}

class SubmitButtonControl extends React.Component<Props & FormContextType> {
  onClick = () => {
    const {
      handleSubmit,
      onClick = onDummyClick,
      canSubmit = true,
      getState,
    } = this.props;
    if (canSubmit) {
      handleSubmit && handleSubmit();
    }
    const { hasValidationErrors } = (getState && getState()) || {};
    !hasValidationErrors && onClick();
  };

  render() {
    return this.props.render({
      onClick: this.onClick,
    });
  }
}

export default withFormContext(SubmitButtonControl);
