import { flow, SnapshotOrInstance, types, Instance } from "mobx-state-tree";

import { IMAGE_TYPES } from "constants/imageTypes";
import { ROLES } from "constants/roles";

import api from "common/services/api";

const SimpleModel = types.model({
  name: types.string,
  id: types.number,
});

const AdditionalInfo = types.model({
  role: types.maybe(SimpleModel),
  customer: types.maybe(SimpleModel),
  study: types.maybe(SimpleModel),
  site: types.maybe(SimpleModel),
});

const Profile = types.model({
  firstName: types.maybe(types.string),
  lastName: types.maybe(types.string),
  phoneNumber: types.maybe(types.string),
  profileImage: types.maybe(types.string),
  email: types.maybe(types.string),
  currentRole: types.maybe(SimpleModel),
});

const ProfileStore = types
  .model({
    profile: types.maybe(Profile),
    additionalInfo: types.maybe(AdditionalInfo),
    profilePhoto: types.maybe(types.string),
    isLoading: types.maybe(types.boolean),
    isSaveLoading: types.optional(types.boolean, false),
    isAvatarLoading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    fetch: flow(function*() {
      self.isLoading = true;
      try {
        type Response = Instance<typeof Profile>;
        const profile: Response = yield api.get("/user/-/profile");
        self.profile = profile;
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    fetchAdditionalInfo: flow(function*() {
      self.isLoading = true;
      try {
        type Response = Instance<typeof AdditionalInfo>;
        const additionalInfo: Response = yield api.get("/user/-/profile/role");
        self.additionalInfo = additionalInfo;
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    save: flow(function*(values: SnapshotOrInstance<typeof Profile>) {
      let error = "";
      let isSuccess = false;
      self.isSaveLoading = true;

      try {
        yield api.put("/user/-/profile", values);
        isSuccess = true;
      } catch (e) {
        console.error("error", e);
        error = e.message;
      } finally {
        self.isSaveLoading = false;
      }

      return { isSuccess, error };
    }),
    fetchAvatar: flow(function*() {
      self.profilePhoto = undefined;
      self.isAvatarLoading = true;

      try {
        type Response = { blob?: Blob; contentDisposition?: string };
        const response: Response = yield api.getBlob(
          "/user/-/profile/photo/download",
          {
            imageType: IMAGE_TYPES.ORIGINAL, // TODO: Replace with a smaller format
          },
        );
        if (response.blob) {
          self.profilePhoto = URL.createObjectURL(response.blob);
        }
      } catch (error) {
        console.error("error", error);
        throw error;
      } finally {
        self.isAvatarLoading = false;
      }
    }),
    editAvatar: flow(function*(values: any) {
      let isSuccess = false;
      let error = "";

      try {
        const result = yield api.putFormData(`/user/-/profile/photo`, {
          ...values,
        });
        self.profilePhoto =
          result.items?.find(item => item.imageType === IMAGE_TYPES.ORIGINAL)
            ?.url ?? null; // TODO: Replace with a smaller format
        isSuccess = true;
      } catch (e) {
        console.error("error", e);
        error = e.message;
      }

      return { isSuccess, error };
    }),
  }))
  .views(self => ({
    get name() {
      return [self.profile?.firstName, self.profile?.lastName]
        .filter(Boolean)
        .join(" ");
    },
    get role() {
      return self.profile?.currentRole?.name ?? "";
    },
    get studyId() {
      return self.additionalInfo?.study?.id ?? "";
    },
    get isStudyAdmin() {
      return self.profile?.currentRole?.id === ROLES.STUDY_ADMIN;
    },
  }));

export default ProfileStore.create();
