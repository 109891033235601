import React from "react";

import Header from "common/components/Layout/Header";
import BackgroundLight from "common/components/Layout/Background/BackgroundLight";

type LoaderLayoutProps = {
  children?: React.ReactNode;
};

export function AdminLoaderLayout({ children }: LoaderLayoutProps) {
  return (
    <>
      <Header isOverlay />
      <BackgroundLight />
      {children}
    </>
  );
}
