import React from "react";
import { FieldRenderProps } from "react-final-form";

import { DictionaryItem } from "common/types/models";

import RadioButtonGroup from "common/components/RadioButtonGroup";

import withFieldControl from "../FieldControl";

type Props = {} & FieldRenderProps<any>;

class RadioButtonGroupFieldControl extends React.Component<Props> {
  onChange = (item: DictionaryItem) => {
    const event: any = {
      target: { value: item.value },
    };
    this.props.input.onChange(event);
  };

  get selected() {
    const { input } = this.props;
    return { label: input.name, value: input.value };
  }

  render() {
    const { options } = this.props;
    return (
      <RadioButtonGroup
        options={options}
        onChange={this.onChange}
        selected={this.selected}
      />
    );
  }
}

export default withFieldControl(RadioButtonGroupFieldControl);
