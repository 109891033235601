import React from "react";
import { FieldRenderProps } from "react-final-form";

import { DictionaryItem } from "common/types/models";

import CheckboxGroup from "common/components/CheckboxGroup";

import withFieldControl from "../FieldControl";

type Props = { required?: boolean } & FieldRenderProps<any>;

class CheckboxGroupFieldControl extends React.Component<Props> {
  onChange = (items: DictionaryItem[] = []) => {
    const value = items.map(item => item.value);
    const event: any = { target: { value } };
    this.props.input.onChange(event);
  };

  get selected() {
    const { options, input } = this.props;
    const value = input?.value || [];
    return value.map(item =>
      options?.find(findItem => findItem.value === item),
    );
  }

  render() {
    const { options, required } = this.props;
    return (
      <CheckboxGroup
        options={options}
        onChange={this.onChange}
        selected={this.selected}
        required={required}
      />
    );
  }
}

export default withFieldControl(CheckboxGroupFieldControl);
