import React from "react";
import ReactDOM from "react-dom";

import App from "common/entry";

import "core-js/es/object";

import "core-js/es/array/flat-map";

import "./index.css";

ReactDOM.render(<App />, document.getElementById("root"));
