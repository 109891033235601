import styled from "styled-components";

import DefaultLoader from "common/components/Loader";

import { COLORS } from "common/constants/layout";
import styledWrapper from "../StyledWrapper/StyledWrapper";

export const Button = styled.button`
  font-family: OpenSans-Bold;
  font-size: ${props => props.fontSize ?? "16px"};
  line-height: 1.25;
  letter-spacing: 0.15px;

  text-align: center;

  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  user-select: none;

  outline: none;

  width: 100%;
  height: 48px;

  border-width: ${props => props.borderWidth ?? "0px"};
  border-style: solid;
  border-color: ${props => props.borderColor ?? COLORS.BLUE};
  border-radius: 7px;

  color: ${({ color }) => color ?? COLORS.BUTTON_FONT_COLOR};
  background-color: ${props => props.background ?? COLORS.BLUE};

  &:not(:disabled):hover {
    background-color: ${props => props.hoverColor ?? COLORS.CORNFLOWER};
  }

  &:not(:disabled):active {
    color: ${({ outline }) => (outline ? "#3f47cb" : COLORS.BUTTON_FONT_COLOR)};
    border-color: #3f47cb;
    background-color: ${props => props.activeColor ?? "#3f47cb"};
  }

  &::first-letter {
    text-transform: capitalize;
  }
`;

export const LoaderBlue = styledWrapper(
  styled(DefaultLoader).attrs({ size: 24 })``,
)`
  margin-top: 4px;
`;

export const LoaderWhite = styledWrapper(
  styled(DefaultLoader).attrs({ size: 24, color: "white" })``,
)`
  margin-top: 4px;
`;
