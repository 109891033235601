import * as React from "react";

function SvgI48PlaceholderAvatar(props) {
  return (
    <svg width={48} height={48} {...props}>
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M24 19a6 6 0 105.736 4.233 3 3 0 11-3.97-3.969A5.999 5.999 0 0024 19zm-12.4-9l1.657-4.143C13.667 4.832 14.898 4 15.991 4h16.018c1.1 0 2.325.835 2.734 1.857L36.4 10h5.598A4 4 0 0146 14.004v23.992A4.004 4.004 0 0141.998 42H6.002A4 4 0 012 37.996V14.004A4.004 4.004 0 016.002 10H11.6zm-5.598 4L6 37.996 41.998 38 42 14.004 34.694 14c-.553 0-1.168-.412-1.374-.924L31.28 8H16.707l-2.025 5.062c-.207.518-.821.938-1.377.938H6.002zM14 25c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10-10-4.477-10-10z"
      />
    </svg>
  );
}

export default SvgI48PlaceholderAvatar;
