import React from "react";
import { FieldRenderProps } from "react-final-form";

import { Field } from "common/components/Form";
import MultiEmailInput, {
  MultiEmailExternalProps,
} from "common/components/Input/MultiEmailInput";

import withFieldControl from "../FieldControl";

import {
  compose,
  emailValidator,
  emptyValidator,
  firstOfArrayValidator,
} from "common/utils/validators";

type Props = {
  title?: string;
  required?: boolean;
  name: string;
} & MultiEmailExternalProps;

class MultiEmailFieldControl extends React.Component<
  Props & FieldRenderProps<any>
> {
  onChange = (emails: string[]) => {
    const value = emails.length === 0 ? null : emails;
    const event: any = {
      target: { value },
    };
    this.props.input.onChange(event);
  };

  render() {
    const { title, required, input, meta, ...props } = this.props;
    return (
      <Field title={title} required={required}>
        <MultiEmailInput
          onChange={this.onChange}
          value={input.value}
          error={meta.touched && meta.error}
          {...props}
        />
      </Field>
    );
  }
}

const Control = withFieldControl(MultiEmailFieldControl);

export default class ExportedControl extends React.Component<
  Props & FieldRenderProps<any>
> {
  render() {
    return (
      <Control
        {...this.props}
        validate={firstOfArrayValidator(
          this.props.required
            ? compose(emptyValidator, emailValidator)
            : emailValidator,
        )}
      />
    );
  }
}
