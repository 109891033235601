import styled from "styled-components";

import SvgI5Star from "common/assets/icons/I5Star";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 8px;
`;

export const Title = styled.span`
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 19px;

  color: #919bb8;
`;

export const Star = styled(SvgI5Star)`
  margin: -4px 0 0 3px;
`;

export const Action = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: -12px;
`;
