import styled from "styled-components";

import { COLORS } from "common/constants/layout";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 19px;
`;

export const Title = styled.div`
  font-family: OpenSans-Bold;
  font-size: 30px;
  line-height: 41px;

  color: ${COLORS.GRAY};
`;

export const Subtitle = styled.div`
  color: ${props =>
    props.subtitleLight ? `${COLORS.GRAY}` : `${COLORS.TEXT_LIGHT}`};
`;
