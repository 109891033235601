import React from "react";
import { observable, computed } from "mobx";
import { observer } from "mobx-react";

import ProfileStore from "stores/Profile";

import { auth } from "stores/Auth";
import {
  withGlobalNavigation,
  NavigationContextProps,
} from "navigation/context";

import withGlobalPopup, {
  GlobalPopupProps,
} from "common/components/Popup/withGlobalPopup";
import { ContentProps } from "common/services/popup";
import { MenuItem } from "common/components/Menu";
import OutsideClickHandler from "common/components/OutsideClickHandler";
import EditProfileForm from "./EditProfileForm";
import LetterAvatar from "common/components/LetterAvatar";

import SvgI16Dropdown from "common/assets/icons/I16Dropdown";
import SvgI16Logout from "common/assets/icons/I16Logout";
import SvgI16Renew2 from "common/assets/icons/I16Renew2";
import SvgI16Man from "common/assets/icons/I16Man";

import { Avatar, Container, Info, Name, Role, Menu, Fab } from "./styles";

const FabDropdown = () => <Fab icon={<SvgI16Dropdown />} />;

type Props = {} & GlobalPopupProps;

@observer
class UserInfo extends React.Component<Props> {
  @observable isMenuOpened = false;

  @computed get role() {
    const studyName = ProfileStore.additionalInfo?.study?.name;
    return studyName === undefined
      ? ProfileStore.role
      : `${ProfileStore.role} / ${ProfileStore.additionalInfo?.study?.name}`;
  }

  openUserMenu = () => {
    this.isMenuOpened = true;
  };

  closeUserMenu = () => {
    this.isMenuOpened = false;
  };

  openProfile = () => {
    this.props.openPopup({
      key: "EditProfile",
      render: this.renderModalContent,
    });
  };

  renderModalContent = ({ close }: ContentProps) => (
    <EditProfileForm
      onClose={close}
      onSubmit={async (values: any) => {
        const { isSuccess } = await ProfileStore.save(values);
        if (isSuccess) {
          close();
          await ProfileStore.fetch();
        }
      }}
      isLoading={ProfileStore.isSaveLoading}
      initialValues={ProfileStore.profile}
    />
  );

  render() {
    return (
      <OutsideClickHandler onClick={this.closeUserMenu}>
        <Container onClick={this.openUserMenu}>
          {!ProfileStore.isAvatarLoading &&
            (ProfileStore.profilePhoto ? (
              <Avatar
                style={{ backgroundImage: `url(${ProfileStore.profilePhoto})` }}
              />
            ) : (
              <LetterAvatar
                name={ProfileStore.name}
                size={48}
                fontSize={24}
                borderOnDarkBackground={true}
              />
            ))}

          <Info>
            <Name>{ProfileStore.name}</Name>
            <Role>{this.role}</Role>
          </Info>
          <FabDropdown />
          {this.isMenuOpened && (
            <UserMenu
              closeUserMenu={this.closeUserMenu}
              openProfile={this.openProfile}
            />
          )}
        </Container>
      </OutsideClickHandler>
    );
  }
}

export default withGlobalPopup(UserInfo);

type MenuProps = {
  closeUserMenu: () => void;
  openProfile: () => void;
} & NavigationContextProps;

class UserMenuComponent extends React.Component<MenuProps> {
  openProfile = () => {
    const { closeUserMenu, openProfile } = this.props;
    closeUserMenu();
    openProfile();
  };

  changeRole = () => {
    const { navigateToLoginByRole } = this.props;
    navigateToLoginByRole();
  };

  logout = async () => {
    const { closeUserMenu, navigateToLogin } = this.props;
    closeUserMenu();
    auth.signOut();
    navigateToLogin();
  };

  render() {
    return (
      <Menu>
        <MenuItem
          icon={<SvgI16Man />}
          value="My profile"
          onClick={this.openProfile}
        />
        <MenuItem
          icon={<SvgI16Renew2 />}
          value="Change role"
          onClick={this.changeRole}
        />
        <MenuItem
          icon={<SvgI16Logout />}
          value="Logout"
          onClick={this.logout}
        />
      </Menu>
    );
  }
}

const UserMenu = withGlobalNavigation(UserMenuComponent);
