type DownloadProperties = {
  blob: Blob;
  contentDisposition: string;
};

export function download({ blob, contentDisposition }: DownloadProperties) {
  const url = window.URL.createObjectURL(blob);
  const anchorElement = document.createElement("a");
  anchorElement.href = url;

  anchorElement.download = getFilename(contentDisposition);

  document.body.appendChild(anchorElement);
  anchorElement.click();
  anchorElement.remove();
}

function getFilename(contentDisposition: string): string {
  const match = contentDisposition.match(
    /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
  );
  if (match && match.length >= 2) return match[1];
  return "download";
}

type DownloadURIProps = { uri: string; name: string; target?: string };

export function downloadURI({ uri, name, target }: DownloadURIProps) {
  const anchorElement = document.createElement("a");
  anchorElement.download = name;
  anchorElement.href = uri;
  anchorElement.target = target ?? "";
  document.body.appendChild(anchorElement);
  anchorElement.click();
  anchorElement.remove();
}
