import React from "react";
import { FieldInputProps } from "react-final-form";

import {
  Container,
  Wrapper,
  Input,
  Label,
  Note,
  NoteText,
  Error,
} from "./styles";

import { MaskProperties } from "common/constants/forms";
import SvgI16Attention from "common/assets/icons/I16Attention";

export type NumericInputExternalProps = {
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  required?: boolean;
  autoComplete?: string;
  mask?: MaskProperties;
  label?: string;
  note?: string;
  min?: number;
  canStretchOnError?: boolean;
};

type Props = {
  error?: string;
  input: FieldInputProps<number>;
} & NumericInputExternalProps;

class NumericInput extends React.Component<Props> {
  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const {
      min,
      input: { onChange },
    } = this.props;
    const value = e.currentTarget.value;
    if (typeof min === "number") {
      parseFloat(value) >= min ? onChange(value) : onChange(min);
    } else {
      onChange(value);
    }
  };

  render() {
    const {
      error,
      disabled,
      placeholder,
      type,
      required,
      autoComplete,
      mask,
      input,
      label,
      note,
      canStretchOnError,
    } = this.props;
    return (
      <Container>
        <Wrapper>
          <Input
            {...input}
            disabled={disabled}
            placeholder={placeholder}
            hasError={error}
            onChange={this.onChange}
            type={type}
            required={required}
            autoComplete={autoComplete}
            {...mask}
          />
          {label && <Label>{label}</Label>}
          {note && (
            <Note>
              <SvgI16Attention />
              <NoteText>{note}</NoteText>
            </Note>
          )}
        </Wrapper>
        {error && <Error isStatic={canStretchOnError}>{error}</Error>}
      </Container>
    );
  }
}

export default NumericInput;
