import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";

import Icon from "common/components/Icon";

import SvgI16Check from "common/assets/icons/I16Check";

import { COLORS } from "common/constants/layout";

import {
  Container,
  Title,
  Description,
  RadioButtonGroup,
  RightContainer,
  CheckMark,
} from "./styles";

import { DictionaryItem } from "common/types/models";

type Props = {
  title: string;
  roleOptions: Array<DictionaryItem>;
  onSelect: (option: DictionaryItem, index: number) => void;
  icon?: React.ReactNode;
  isSelected?: boolean;
};

@observer
class SelectorItem extends React.Component<Props> {
  @observable selected = this.props.roleOptions[0];
  @observable index = 0;

  onSelect = () => {
    const { onSelect } = this.props;
    onSelect(this.selected, this.index);
  };

  onChange = (option: DictionaryItem, index: number) => {
    this.selected = option;
    this.index = index;
  };

  render() {
    const { isSelected, icon, title, roleOptions } = this.props;
    return (
      <Container isSelected={isSelected} onClick={this.onSelect}>
        {icon}

        <RightContainer>
          <Title>{title}</Title>
          <Description>
            {roleOptions.length === 1 ? (
              this.selected.label
            ) : (
              <RadioButtonGroup
                disabled={!isSelected}
                options={roleOptions}
                onChange={this.onChange}
                selected={this.selected}
                isSelected={(option: DictionaryItem) =>
                  !!(this.selected && this.selected.label === option.label)
                }
              />
            )}
          </Description>
        </RightContainer>

        {isSelected && (
          <CheckMark>
            <Icon icon={<SvgI16Check />} color={COLORS.CORNFLOWER} />
          </CheckMark>
        )}
      </Container>
    );
  }
}

export default SelectorItem;
