import React from "react";
import { FieldInputProps } from "react-final-form";

import {
  Container,
  Wrapper,
  Input,
  Label,
  Note,
  NoteText,
  Error,
} from "./styles";

import { MaskProperties } from "common/constants/forms";
import SvgI16Attention from "common/assets/icons/I16Attention";

export type TextInputExternalProps = {
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  required?: boolean;
  autoComplete?: string;
  mask?: MaskProperties;
  label?: string;
  note?: string;
  canStretchOnError?: boolean;
};

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  error?: string;
  input: FieldInputProps<string>;
} & TextInputExternalProps;

class TextInput extends React.Component<Props> {
  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    onChange && onChange(e.currentTarget.value);
  };

  render() {
    const {
      value,
      error,
      disabled,
      placeholder,
      type,
      required,
      autoComplete,
      mask,
      input,
      label,
      note,
      canStretchOnError,
    } = this.props;
    return (
      <Container>
        <Wrapper>
          <Input
            disabled={disabled}
            placeholder={placeholder}
            hasError={error}
            value={value}
            onChange={this.onChange}
            type={type}
            required={required}
            autoComplete={autoComplete}
            {...input}
            {...mask}
          />
          {label && <Label>{label}</Label>}
          {note && (
            <Note>
              <SvgI16Attention />
              <NoteText>{note}</NoteText>
            </Note>
          )}
        </Wrapper>
        {error && <Error isStatic={canStretchOnError}>{error}</Error>}
      </Container>
    );
  }
}

export default TextInput;
