type Validator = (value: any) => string;

export const compose = (...validators: Array<Validator>) => (value: any) => {
  let result;
  for (let index = 0; index < validators.length; index++) {
    result = validators[index](value);
    if (result) return result;
  }
  return "";
};

// TODO add memoization

export const emailValidator = (email: string) => {
  // See https://emailregex.com
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!email || email.length <= 0) return "";
  if (!re.test(email)) return "A valid email address is required.";

  return "";
};

export const passwordValidator = (password: string) => {
  // TODO: improve validation rule
  if (!password || password.length < 6)
    return "Password length should be at least 6 characters.";

  return "";
};

export const emptyValidator = (value: any) => {
  return Number.isFinite(value) ||
    Boolean(typeof value === "string" ? value.trim() : value)
    ? ""
    : "This field is required.";
};

const phoneNumberRegexp = new RegExp(
  `^[+][0-9][(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$`,
);

export const phoneNumberValidator = (phoneNumber = "") => {
  return phoneNumberRegexp.test(phoneNumber.trim()) || phoneNumber === ""
    ? null
    : "Please enter a valid phone number.";
};

const zipCodeRegexp = new RegExp(`^[0-9]{5}`);

export const zipValidator = (value = "") =>
  zipCodeRegexp.test(value.trim()) ? null : "Please enter a valid zip code.";

export const armsValidator = (value = "") =>
  parseInt(value) > 0 ? null : "Please enter a valid number of arms.";

export const lengthValidator = (maxLength = 1) => (value = "") =>
  value.length > maxLength
    ? `The length must be ${maxLength} characters or fewer.`
    : null;

export const firstOfArrayValidator = (validator: Validator) => (
  values: Array<any>,
): string => {
  for (const value of values) {
    const result = validator(value);
    if (result) return result;
  }
  return "";
};

export const emptyArrayValidator = (validator: Validator) => (
  values: Array<any>,
): string => {
  return (Array.isArray(values) && values.length > 0) ||
    (!Array.isArray(values) && validator(values) === "")
    ? ""
    : "This field is required.";
};
