import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  & path,
  & use {
    fill: ${({ color }) => color};
  }

  &:hover path,
  &:hover use {
    fill: ${({ hoverColor, color }) => hoverColor ?? color};
  }
`;
