import React from "react";
import { FieldRenderProps } from "react-final-form";

import Dropdown, {
  DropdownExternalProps,
} from "common/components/Input/Dropdown";
import { Field } from "common/components/Form";

import withFieldControl from "../FieldControl";

import { DictionaryItem } from "common/types/models";
import { emptyValidator, emptyArrayValidator } from "common/utils/validators";

type Props = {
  title?: string;
  required?: boolean;
  name: string;
  validate?: (value: any) => boolean;
} & DropdownExternalProps;

class DropdownFieldControl extends React.Component<
  FieldRenderProps<any> & Props
> {
  onMultiChange = (items: DictionaryItem[] = []) => {
    const value = items.map(item => item.value);
    const event: any = { target: { value } };
    this.props.input.onChange(event);
  };

  onChange = (item: DictionaryItem) => {
    const event: any = { target: { value: item.value } };
    this.props.input.onChange(event);
  };

  render() {
    const { title, required, input, meta, multi, ...props } = this.props;
    // TODO use suitable type
    return (
      <Field title={title} required={required}>
        <Dropdown
          input={input}
          onChange={multi ? this.onMultiChange : this.onChange}
          error={meta.touched && meta.error}
          multi={multi}
          {...props}
        />
      </Field>
    );
  }
}

const Control = withFieldControl(DropdownFieldControl);

export default class ExportedControl extends React.Component<
  Props & FieldRenderProps<any>
> {
  render() {
    const { required } = this.props;
    return (
      <Control
        {...this.props}
        validate={required ? emptyArrayValidator(emptyValidator) : () => {}}
      />
    );
  }
}
