import styled from "styled-components";

import { COLORS } from "common/constants/layout";

export const Button = styled.div`
  border-radius: 8px;
  text-align: center;
  font-size: 18px;

  position: relative;

  color: ${({ isActive }) =>
    isActive ? COLORS.CORNFLOWER : COLORS.TEXT_LIGHT};
  background-color: ${({ isActive }) =>
    isActive ? "rgba(0, 0, 0, 0.3)" : "transparent"};
  & > svg > path,
  & > svg > use {
    fill: ${({ isActive }) =>
      isActive ? COLORS.CORNFLOWER : COLORS.TEXT_LIGHT};
  }

  &:hover {
    color: ${COLORS.CORNFLOWER};
  }
  &:hover > svg > path,
  &:hover > svg > use {
    fill: ${COLORS.CORNFLOWER};
  }

  width: 100px;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  cursor: pointer;
  user-select: none;
`;

export const Title = styled.p`
  font-size: 14px;
  font-family: "OpenSans-SemiBold";
  margin-top: 8px;
`;

export const Counter = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  width: 24px;
  height: 24px;

  background-color: ${COLORS.CORNFLOWER};
  color: #ffffff;

  font-family: OpenSans-SemiBold;
  font-size: 14px;
  font-weight: 600;
  text-align: center;

  line-height: 24px;

  border-radius: 50%;
`;
