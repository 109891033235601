import React from "react";

import { RightContainer, FixedHeader, Container, Logo } from "./styles";

type Props = {
  children?: React.ReactNode;
  isOverlay?: boolean;
};

class Header extends React.Component<Props> {
  render() {
    const { isOverlay, children } = this.props;
    return (
      <Container isOverlay={isOverlay}>
        <FixedHeader>
          <Logo />
          <RightContainer>{children}</RightContainer>
        </FixedHeader>
      </Container>
    );
  }
}

export default Header;
