import styled from "styled-components";

import IconButtonOrig from "common/components/Button/IconButton";

export const Container = styled.div`
  position: absolute;

  z-index: 100;
  min-width: 156px;

  padding: 6px 0;

  border-radius: 7px;
  background-color: white;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.11);

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 7px solid #fff;

    position: absolute;
    top: -5px;
    right: 8px;
  }
`;

export const IconButton = styled(IconButtonOrig)`
  width: 100%;
  height: 40px;
  padding: 0 12px 0 20px;
  border-radius: 0;

  justify-content: flex-start;

  &:not(:last-child) {
    border-bottom: solid 1px rgba(55, 56, 61, 0.07);
  }
`;
