import styled from "styled-components";

export const TITLE_HEIGHT = "120px";

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  height: ${TITLE_HEIGHT};
  min-height: ${TITLE_HEIGHT};
  max-height: ${TITLE_HEIGHT};
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
`;

export const RightContent = styled.div`
  display: flex;
`;

export const TitleText = styled.h1`
  font-size: 36px;
  font-family: OpenSans-Bold;
  font-size: 30px;
  font-stretch: normal;
  font-style: normal;

  user-select: none;

  line-height: normal;
  letter-spacing: normal;

  color: #37383d;
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;

  margin: 4px 0 0 16px;
  user-select: none;
`;
