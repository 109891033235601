import styled, { css } from "styled-components";
import DefaultSelect from "react-select";

import DefaultCheckbox from "common/components/Checkbox/Checkbox";

const selectStyles = {
  control: (provided, props) => ({
    ...provided,
    height: "100%",
    borderWidth: "2px",
    borderColor: props.selectProps.error ? "#f74a70" : "#d8ddeb",
  }),
  placeholder: provided => ({
    ...provided,
    color: "rgba(51,51,51,0.4)",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  valueContainer: provided => ({
    ...provided,
    padding: "0 16px",
  }),
  menu: provided => ({
    ...provided,
    minWidth: "100%",
    width: "auto",
  }),
  input: provided => ({
    ...provided,
    fontFamily: "OpenSans-Regular",
    fontSize: "16px",
  }),
  option: provided => ({
    ...provided,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "rgba(164, 170, 246, 0.08)",
    },
  }),
};

export const Select = styled(DefaultSelect).attrs({ styles: selectStyles })`
  width: 100%;
  height: 48px;

  & input::placeholder {
    color: rgba(51, 51, 51, 0.4);
  }
`;

export const Container = styled.div`
  width: 100%;

  position: relative;
`;

export const Checkbox = styled(DefaultCheckbox)`
  white-space: nowrap;

  p {
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.15px;
    color: #37383d;

    ${({ isChecked }) =>
      isChecked &&
      css`
        color: #5682f6;
      `}
  }
`;

export const Label = styled.span`
  display: flex;
  align-items: center;
`;

export const Error = styled.p`
  position: absolute;

  font-size: 13px;
  font-family: "OpenSans-Regular";

  color: #f74a70;

  margin-top: 4px;
`;
