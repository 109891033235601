import styled from "styled-components";

export const Version = styled.div`
  position: fixed;
  right: 6px;
  bottom: 4px;

  font-family: OpenSans;
  font-size: 12px;
  color: #a4aaf6;
`;
