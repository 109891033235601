import React from "react";
import { FieldRenderProps } from "react-final-form";

import CheckboxesDropdown, {
  CheckboxesDropdownExternalProps,
} from "common/components/Input/CheckboxesDropdown";
import { Field } from "common/components/Form";

import withFieldControl from "../FieldControl/FieldControl";

import { DictionaryItem } from "common/types/models";
import { emptyValidator, emptyArrayValidator } from "common/utils/validators";

type Props = {
  title?: string;
  required?: boolean;
  name: string;
  validate?: (value: any) => boolean;
} & CheckboxesDropdownExternalProps;

class CheckboxesDropdownFieldControl extends React.Component<
  FieldRenderProps<any> & Props
> {
  onMultiChange = (items: DictionaryItem[]) => {
    const value = (items || []).map(item => item.value);
    const event: any = { target: { value } };
    this.props.input.onChange(event);
  };

  render() {
    const { title, required, input, meta, ...props } = this.props;
    // TODO use suitable type
    return (
      <Field title={title} required={required}>
        <CheckboxesDropdown
          input={input}
          onChange={this.onMultiChange}
          error={meta.touched && meta.error}
          {...props}
        />
      </Field>
    );
  }
}

const Control = withFieldControl(CheckboxesDropdownFieldControl);

export default class ExportedControl extends React.Component<
  Props & FieldRenderProps<any>
> {
  render() {
    return (
      <Control {...this.props} validate={emptyArrayValidator(emptyValidator)} />
    );
  }
}
