import React from "react";
import { observer } from "mobx-react";

import { ButtonBlue, ButtonOutline } from "common/components/Button";

import {
  Title,
  Text,
  Error,
  Container,
  ButtonWrapper,
  Controls,
} from "./style";

type Props = {
  title: string;
  text: string;
  error?: string;

  acceptText: string;
  declineText?: string;

  isLoading?: boolean;

  onAccept: () => void;
  onDecline?: () => void;
};

@observer
export default class ConfirmationDialog extends React.Component<Props> {
  render() {
    const {
      title,
      text,
      error,
      acceptText,
      declineText,
      isLoading,
      onAccept,
      onDecline,
    } = this.props;
    return (
      <Container>
        <Title>{title}</Title>
        <Text>{text}</Text>

        {error && <Error>{error}</Error>}

        <Controls>
          {declineText && onDecline && (
            <ButtonWrapper>
              <ButtonOutline onClick={onDecline}>{declineText}</ButtonOutline>
            </ButtonWrapper>
          )}

          <ButtonWrapper>
            <ButtonBlue onClick={onAccept} isLoading={isLoading}>
              {acceptText}
            </ButtonBlue>
          </ButtonWrapper>
        </Controls>
      </Container>
    );
  }
}
