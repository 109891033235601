import React from "react";

import { Button, Text, Icon } from "./styles";

type Props = {
  onClick: () => void;
  icon: React.ReactNode;
  isRight?: boolean;
  text?: string;
  isGreyed?: boolean;
  shouldStopPropagation?: boolean;
  className?: any;
  disabled?: boolean;
  disabledColor?: string;
};

function IconButton({
  onClick,
  icon,
  text,
  isRight,
  isGreyed,
  shouldStopPropagation,
  className,
  disabled,
  disabledColor,
}: Props) {
  const onClickHandle = (e: { [key: string]: any }) => {
    if (shouldStopPropagation) {
      e.stopPropagation();
    }
    onClick();
  };

  return (
    <Button
      className={className}
      onClick={onClickHandle}
      isGreyed={isGreyed}
      disabled={disabled}
      disabledColor={disabledColor}
    >
      {!isRight && <Icon>{icon}</Icon>}
      {text && <Text>{text}</Text>}
      {isRight && <Icon>{icon}</Icon>}
    </Button>
  );
}

export default IconButton;
