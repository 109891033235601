import React, { ReactNode } from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";

import TabButton from "./TabButton";
import BackBar from "common/components/Layout/BackBar";

type Props = {
  children: Array<ReactNode>;
  titles: Array<string>;
  onBackClick?: () => void;
  backButtonText?: string;
};

@observer
export default class Tabs extends React.Component<Props> {
  @observable currentTabIndex: number = 0;

  onTabClick = ({ tabIndex }: { tabIndex: number }) => {
    this.currentTabIndex = tabIndex;
  };

  componentDidMount() {
    const { children, titles } = this.props;

    if (children.length !== titles.length) {
      throw new Error(
        "Count of the children is not equal to count of the titles.",
      );
    }
  }

  render() {
    const { onBackClick, backButtonText, children, titles } = this.props;

    return (
      <BackBar
        onBackClick={onBackClick}
        backButtonText={backButtonText || "Back"}
        header={titles.map((title: string, index: number) => (
          <TabButton
            key={index}
            label={title}
            tabIndex={index}
            onClick={this.onTabClick}
            isActive={index === this.currentTabIndex}
          />
        ))}
      >
        {React.Children.toArray(children)[this.currentTabIndex]}
      </BackBar>
    );
  }
}
