import React from "react";
import { observer } from "mobx-react";

import FormControl, { TextFieldControl } from "common/components/FormControl";
import { Title } from "common/components/Form";
import { ButtonBlue } from "common/components/Button";
import ProfileAvatar from "./ProfileAvatar";

import { emailValidator, phoneNumberValidator } from "common/utils/validators";

import { MASKS } from "common/constants/forms";

import {
  Container,
  Row,
  Cell,
  Buttons,
  ButtonBack,
  ButtonSubmit,
} from "./styles";

type FormSubmitData = {
  values: any;
};

type Props = {
  onClose: () => void;
  onSubmit: (data: FormSubmitData) => void;
  isLoading: boolean;
  initialValues: any;
};

@observer
class AddStudyForm extends React.Component<Props> {
  render() {
    const { onClose, onSubmit, initialValues } = this.props;
    return (
      <FormControl onSubmit={onSubmit} initialValues={initialValues}>
        <Container>
          <Title title="My Profile" />
          <ProfileAvatar />
          <Row>
            <Cell>
              <TextFieldControl
                name="firstName"
                title="First name"
                placeholder="First name"
                required
              />
            </Cell>
            <Cell>
              <TextFieldControl
                name="lastName"
                title="Last name"
                placeholder="Last name"
                required
              />
            </Cell>
          </Row>
          <Row>
            <TextFieldControl
              name="phoneNumber"
              title="Mobile number"
              placeholder="+1(XXX)-XXX-XXXX"
              mask={MASKS.PHONE}
              validate={phoneNumberValidator}
            />
          </Row>
          <Row>
            <TextFieldControl
              name="email"
              title="Email"
              placeholder="Email"
              validate={emailValidator}
              disabled
            />
          </Row>
        </Container>
        <Buttons>
          <ButtonBack onClick={onClose}>Cancel</ButtonBack>
          <ButtonSubmit render={this.renderButton} />
        </Buttons>
      </FormControl>
    );
  }

  renderButton = ({ onClick }: { onClick: () => void }) => (
    <ButtonBlue onClick={onClick} isLoading={this.props.isLoading}>
      Save
    </ButtonBlue>
  );
}

export default AddStudyForm;
