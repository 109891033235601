import React from "react";

export type NavigationContextProps = {
  navigateToLogin: () => void;
  navigateToLoginByRole: () => void;
};

const GlobalNavigationContext = React.createContext<NavigationContextProps>({
  navigateToLogin: () => {},
  navigateToLoginByRole: () => {},
});

export default GlobalNavigationContext;

export const withGlobalNavigation = (
  Wrapped: React.ClassType<any, any, any>,
) => (props: any) => {
  function createContextConsumer(context: NavigationContextProps) {
    return <Wrapped {...props} {...context} />;
  }

  return (
    <GlobalNavigationContext.Consumer>
      {createContextConsumer}
    </GlobalNavigationContext.Consumer>
  );
};
