import * as React from "react";

function SvgI16Attention(props) {
  return (
    <svg width={16} height={16} {...props}>
      <path
        fill="#5682F6"
        d="M8 0a8 8 0 110 16A8 8 0 018 0zm0 2a6 6 0 100 12A6 6 0 008 2zm0 8a1 1 0 110 2 1 1 0 010-2zm0-6a1 1 0 011 1v3a1 1 0 11-2 0V5a1 1 0 011-1z"
      />
    </svg>
  );
}

export default SvgI16Attention;
