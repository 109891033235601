import React, { ReactNode } from "react";

import { Container, IconButton } from "./styles";

type Props = {
  children: React.ReactNode;
  className?: string;
};

export class Menu extends React.Component<Props> {
  render() {
    const { children, className } = this.props;
    return <Container className={className}>{children}</Container>;
  }
}

type ItemProps = {
  value: string | number;
  label?: string;
  onClick: (value: string | number) => void;
  icon?: ReactNode;
};

export class MenuItem extends React.Component<ItemProps> {
  render() {
    const { value, label, icon } = this.props;
    return (
      <IconButton
        key={value}
        icon={icon}
        onClick={this.onClick}
        text={label ?? value}
        shouldStopPropagation={true}
        isGreyed={true}
      />
    );
  }

  onClick = () => {
    const { onClick, value } = this.props;
    onClick && onClick(value);
  };
}
