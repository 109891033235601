import styled from "styled-components";
import { TITLE_HEIGHT } from "./Title.styles";

import styledWrapper from "common/components/StyledWrapper";
import DefaultLoader from "common/components/Loader";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 40px;

  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`;

export const WrapperTable = styled.div`
  display: table;

  height: 100%;
  width: 100%;
  min-width: 700px;
`;

export const Content = styled.div`
  position: relative;
  min-width: 100%;
  width: 0;
  flex: 1;
`;

// TODO get margin from constant
export const Loader = styledWrapper(DefaultLoader)`
  margin-top: -${TITLE_HEIGHT};
  height: 100%;
`;
