import React from "react";
import { FieldRenderProps } from "react-final-form";

import DatePicker, {
  DatePickerExternalProps,
} from "common/components/Input/DatePicker";
import { Field } from "common/components/Form";

import withFieldControl from "../FieldControl";

type Props = {
  title?: string;
  required?: boolean;
  name: string;
} & DatePickerExternalProps;

class DateFieldControl extends React.Component<FieldRenderProps<any> & Props> {
  onChange = (date: Date) => {
    const event: any = {
      target: { value: date },
    };
    this.props.input.onChange(event);
  };

  render() {
    const { title, required, input, meta, ...props } = this.props;

    return (
      <Field title={title} required={required}>
        <DatePicker
          input={input}
          onChange={this.onChange}
          error={meta.touched && meta.error}
          {...props}
        />
      </Field>
    );
  }
}

export default withFieldControl(DateFieldControl);
