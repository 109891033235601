import * as React from "react";

function SvgI24Dashboard(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        fill="#5682F6"
        d="M3 13.5c0-.276.214-.5.505-.5h.99c.279 0 .505.23.505.5v7c0 .276-.214.5-.505.5h-.99A.507.507 0 013 20.5v-7zm4-2.999c0-.277.214-.501.505-.501h.99c.279 0 .505.23.505.501V20.5a.496.496 0 01-.505.501h-.99A.506.506 0 017 20.499V10.5zM15 6.5c0-.276.214-.499.505-.499h.99c.279 0 .505.224.505.5v14c0 .277-.214.5-.505.5h-.99a.502.502 0 01-.505-.5v-14zm4 5.01a.5.5 0 01.505-.509h.99c.279 0 .505.228.505.51v8.98a.5.5 0 01-.505.51h-.99a.507.507 0 01-.505-.51v-8.98zM11 3.5c0-.276.214-.5.505-.5h.99c.279 0 .505.228.505.5v17c0 .276-.214.5-.505.5h-.99a.505.505 0 01-.505-.5v-17z"
      />
    </svg>
  );
}

export default SvgI24Dashboard;
