import { MaskOptions } from "react-input-mask";

const DEFAULT_FORMAT_CHARS = {
  "9": "[0-9]",
  a: "[A-Za-z]",
  "*": "[A-Za-z0-9]",
};

export type MaskProperties = MaskOptions;

type MaskCollection = {
  [type: string]: MaskProperties;
};

export const MASKS: MaskCollection = {
  PHONE: {
    mask: "+1(999)-999-9999",
    alwaysShowMask: false,
    maskChar: "_",
    formatChars: DEFAULT_FORMAT_CHARS,
    permanents: [],
  },
  ZIP: {
    mask: "99999",
    alwaysShowMask: false,
    maskChar: "",
    formatChars: DEFAULT_FORMAT_CHARS,
    permanents: [],
  },
  PERIOD: {
    mask: "999",
    alwaysShowMask: false,
    maskChar: "",
    formatChars: DEFAULT_FORMAT_CHARS,
    permanents: [],
  },
  ARMS: {
    mask: "999",
    alwaysShowMask: false,
    maskChar: "",
    formatChars: DEFAULT_FORMAT_CHARS,
    permanents: [],
  },
};
