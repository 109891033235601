import styled from "styled-components";

import { COLORS } from "common/constants/layout";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GreetingTitle = styled.p`
  font-family: OpenSans-SemiBold;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.CORNFLOWER};
`;

export const HeaderTitle = styled.p`
  font-family: OpenSans-Bold;
  font-size: 30px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #37383d;
`;
