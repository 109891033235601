import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  ${props =>
    props.fullWidth ? ".react-datepicker-wrapper {width: 100%  }" : ""}
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Input = styled.input`
  max-width: ${props => (props.fullWidth ? "100%" : "144px")};
  width: 100%;
  height: 48px;

  cursor: pointer;

  padding: 0 16px;

  color: #333333;
  background-color: ${props =>
    props.disabled ? "rgba(158, 170, 206, 0.4)" : "transparent"};

  font-family: OpenSans-Regular;
  font-size: 16px;

  outline: none;

  border-radius: 7px;
  border: ${props =>
    props.hasError ? "solid 2px #f74a70;" : "solid 2px #d8ddeb"};

  &::placeholder {
    color: rgba(51, 51, 51, 0.4);
  }

  &:active {
    border: ${props =>
      props.hasError ? "solid 2px #f74a70;" : "solid 2px #5682f6"};
  }

  &:focus {
    border: ${props =>
      props.hasError ? "solid 2px #f74a70;" : "solid 2px #5682f6"};
  }
`;

export const InputIconContainer = styled.div`
  display: flex;

  position: absolute;
  right: 13px;
  top: 50%;

  transform: translateY(-50%);

  cursor: pointer;
`;

export const Error = styled.p`
  position: absolute;

  font-size: 13px;
  font-family: "OpenSans-Regular";

  color: #f74a70;

  margin-top: 4px;
`;
