import styled from "styled-components";
import { COLORS } from "common/constants/layout";

export const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: -1;

  background: linear-gradient(
    ${COLORS.CORNFLOWER_DARK},
    ${COLORS.DARK_CORNFLOWER}
  );
`;
