import React from "react";

import { Container, TitleContainer, Title, Star, Action } from "./styles";

type Props = {
  title?: string;
  required?: boolean;
  action?: React.ReactNode;
  children: React.ReactNode;
};

function Field({ title, required, action, children }: Props) {
  return (
    <Container>
      {title && (
        <TitleContainer>
          <Title>{title}</Title>
          {required && <Star />}
          {action && <Action>{action}</Action>}
        </TitleContainer>
      )}
      {children}
    </Container>
  );
}

export default Field;
