import styled from "styled-components";
import { COLORS } from "common/constants/layout";

export const Link = styled.a`
  font-family: OpenSans-SemiBold;
  font-size: 14px;
  letter-spacing: 0;
  text-decoration: none;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  color: ${COLORS.CORNFLOWER};
`;
