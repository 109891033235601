const ROUTES = {
  LOGIN: "/login",
  REGISTER: "/register",
  LOGIN_BY_ROLE: "/login-by-role",
  SUPER_ADMIN: "/super-admin",
  CUSTOMER_ADMIN: "/customer-admin",
  STUDY_ADMIN: "/study-admin",
};

export default ROUTES;
