import styledWrapper from "common/components/StyledWrapper";

import Link from "common/components/Link";
import { ButtonBlue } from "common/components/Button";

export const ForgotPasswordLink = styledWrapper(Link)`
  margin-top: 20px;
`;

export const LoginButton = styledWrapper(ButtonBlue)`
  margin: 10px 0 16px;
`;
