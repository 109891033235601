import styled from "styled-components";
import { COLORS } from "common/constants/layout";

const DEFAULT_SIZE = 40;

export const Container = styled.div`
  width: ${({ size }) => size ?? DEFAULT_SIZE}px;
  height: ${({ size }) => size ?? DEFAULT_SIZE}px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  font-family: OpenSans-Bold;
  letter-spacing: 0.15px;

  font-size: ${({ fontSize }) => fontSize}px;

  color: white;
  background-image: ${({ background }) => background};
  border: ${props => (props.hasBorder ? `2px solid ${COLORS.BACK}` : "none")};
`;
