import styled from "styled-components";

export const Container = styled.div`
  opacity: ${({ disabled }) => (disabled ? "0.4" : "1")};

  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;
