import * as React from "react";

function SvgI16ArrowBack(props) {
  return (
    <svg width={16} height={16} {...props}>
      <path
        fill="#5682F6"
        fillRule="evenodd"
        d="M2.293 8.734a1 1 0 01-.083-1.32l.083-.094 3.99-4.027a1 1 0 011.497 1.32l-.083.094-2.273 2.295h7.489a1 1 0 110 2H5.399l2.298 2.284a1 1 0 01.083 1.32l-.083.094a1 1 0 01-1.32.083l-.094-.083-3.99-3.966z"
      />
    </svg>
  );
}

export default SvgI16ArrowBack;
