import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { observable } from "mobx";
import { observer } from "mobx-react";

import ROUTES from "navigation/routes";

import Background from "common/components/Layout/Background/BackgroundBlue";
import Paper from "common/components/Paper";

import RegisterForm from "./RegisterForm";

import { Container, Content, Logo, Title, Subtitle } from "./styles";

import { RegisterData } from "common/types/models";

import { auth } from "stores/Auth";

type Props = {} & RouteComponentProps;

@observer
class Register extends React.Component<Props> {
  @observable serverError: string = "";

  get userId(): number {
    const userId = new URLSearchParams(this.props.location.search).get(
      "userId",
    );
    return userId ? Number.parseInt(userId) : -1;
  }

  get code(): string {
    const code =
      new URLSearchParams(this.props.location.search).get("code") ?? "";

    return code.replace(/\s/g, "+"); // + - in parameters is not a space encoded, it is just a base64 symbol
  }

  onSubmit = async (data: RegisterData) => {
    if (await this.signIn(data)) {
      this.props.history.push(ROUTES.LOGIN);
    }
  };

  signIn = async (data: RegisterData) => {
    this.serverError = "";
    try {
      await auth.register(data);
      return true;
    } catch (error) {
      this.serverError = error.message;
      return false;
    }
  };

  render() {
    return (
      <Container>
        <Background />
        <Paper>
          <Content>
            <Logo />
            <Title>Welcome to SMART Study Portal!</Title>
            <Subtitle>
              Please fill in information below to finish account creation
            </Subtitle>

            <RegisterForm
              userId={this.userId}
              code={this.code}
              onSubmit={this.onSubmit}
              serverError={this.serverError}
              isLoading={auth.isLoading}
            />
          </Content>
        </Paper>
      </Container>
    );
  }
}

export default withRouter(Register);
