import * as React from "react";

function SvgI16Man(props) {
  return (
    <svg width={16} height={16} {...props}>
      <path
        fill="#A9B4C9"
        fillRule="evenodd"
        d="M8 10c2.817 0 4.98 1.177 6.357 3.48a1 1 0 01-1.717 1.027C11.63 12.82 10.127 12 8 12s-3.632.82-4.643 2.508a1 1 0 01-1.716-1.028C3.02 11.177 5.182 10 8 10zm.023-8.516a3.5 3.5 0 110 7 3.5 3.5 0 010-7zm0 2a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
      />
    </svg>
  );
}

export default SvgI16Man;
