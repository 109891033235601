import React from "react";
import { FieldRenderProps } from "react-final-form";

import NumericInput, {
  NumericInputExternalProps,
} from "common/components/Input/NumericInput";
import { Field } from "common/components/Form";

import withFieldControl from "../FieldControl";

type Props = {
  title?: string;
  required?: boolean;
} & NumericInputExternalProps;

function NumericFieldControl({
  title,
  required,
  input,
  meta,
  ...props
}: FieldRenderProps<any> & Props) {
  return (
    <Field title={title} required={required}>
      <NumericInput
        input={input}
        error={meta.touched && meta.error}
        {...props}
      />
    </Field>
  );
}

export default withFieldControl(NumericFieldControl);
