export function formatNumber(value: number): string {
  return Number(value)
    .toLocaleString()
    .replace(/\s/g, ",");
}

export function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

export async function timeoutAsync(delay: number): Promise<void> {
  return new Promise<void>(resolve => {
    setTimeout(resolve, delay);
  });
}

export const EMPTY_NAME_PLACEHOLDER = "-";

export function fullName({
  firstName,
  lastName,
}: {
  firstName?: string;
  lastName?: string;
}): string {
  return [firstName, lastName].filter(Boolean).join(" ");
}

export function sortByGuiOrder<T>(
  items: Array<T> | undefined,
  getGuiOrderValue: (item: T) => number,
) {
  if (items) {
    items.sort((a, b) => getGuiOrderValue(a) - getGuiOrderValue(b));
  }
}
