import * as React from "react";

function SvgI5Star(props) {
  return (
    <svg width={6} height={6} {...props}>
      <path
        fill="#F74A70"
        d="M3.418 5.361l-.102-1.81 1.523 1.004.418-.74-1.612-.798 1.612-.82-.418-.74L3.316 2.49 3.418.652h-.82l.095 1.845-1.523-1.04-.41.74 1.596.82-1.596.799.41.74 1.523-1.004-.095 1.809z"
      />
    </svg>
  );
}

export default SvgI5Star;
