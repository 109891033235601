import React from "react";
import { computed, observable } from "mobx";
import { observer } from "mobx-react";

import { emailValidator } from "common/utils/validators";

import Icon from "common/components/Icon";
import SvgI16Exit from "common/assets/icons/I16Exit";

import {
  Container,
  Wrapper,
  Emails,
  Placeholder,
  Input,
  Error,
  Badge,
  Email,
  Exit,
} from "./styles";

export type MultiEmailExternalProps = {
  disabled?: boolean;
  placeholder?: string;
  canStretchOnError?: boolean;
};

type Props = {
  value: Array<string>;
  onChange: (items: Array<string>) => void;
  error?: string;
} & MultiEmailExternalProps;

const KEYS_SEPARATORS = ["Enter", "Tab", ";", ",", " "];

@observer
class MultiEmailInput extends React.Component<Props> {
  inputRef: React.RefObject<HTMLDivElement> = React.createRef();

  @observable rawValue = "";
  @observable isFocused = false;
  @observable emails: Set<string> = new Set(this.props.value);

  @computed
  get emailsAsArray() {
    return [...this.emails];
  }

  onInput = (event: React.ChangeEvent<HTMLDivElement>) => {
    this.rawValue = event.currentTarget.innerText.trim();
  };

  addEmail = (event: React.SyntheticEvent<HTMLDivElement>) => {
    event.preventDefault();
    const value = event.currentTarget.innerText.trim();

    if (value) {
      this.emails.add(value);
      this.rawValue = "";
      event.currentTarget.innerText = "";
      this.props.onChange(this.emailsAsArray);
    }
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (KEYS_SEPARATORS.includes(event.key)) {
      this.addEmail(event);
    }
  };

  edit = (oldValue: string, newValue: string) => {
    const newItems = this.emailsAsArray.map(item =>
      item === oldValue ? newValue : item,
    );
    this.emails = new Set(newItems);
    this.props.onChange(this.emailsAsArray);
  };

  remove = (value: string) => {
    this.emails.delete(value);
    this.props.onChange(this.emailsAsArray);
  };

  onFocus = () => {
    this.isFocused = true;
    placeCaretAtEnd(this.inputRef.current);
  };

  onBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    this.isFocused = false;
    this.addEmail(event);
  };

  setFocus = () => {
    // eslint-disable-next-line no-unused-expressions
    this.inputRef.current?.focus();
  };

  onPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    text
      .trim()
      .split(/;|,| /)
      .filter(Boolean)
      .forEach(this.emails.add, this.emails);
    this.props.onChange(this.emailsAsArray);
  };

  render() {
    const { placeholder, error, canStretchOnError } = this.props;
    return (
      <Container canStretchOnError={canStretchOnError}>
        <Wrapper
          onClick={this.setFocus}
          focused={this.isFocused}
          hasError={error}
        >
          <Emails>
            {this.emails.size > 0
              ? this.emailsAsArray.map((email: string, index: number) => (
                  <EmailBadge
                    key={index}
                    email={email}
                    remove={this.remove}
                    edit={this.edit}
                  />
                ))
              : this.rawValue.length === 0 && (
                  <Placeholder>
                    {placeholder ?? "Enter emails separated by commas"}
                  </Placeholder>
                )}
            <Input
              contentEditable
              ref={this.inputRef}
              onInput={this.onInput}
              onKeyDown={this.onKeyDown}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onPaste={this.onPaste}
            />
          </Emails>
        </Wrapper>
        {error && <Error isStatic={canStretchOnError}>{error}</Error>}
      </Container>
    );
  }
}

export default MultiEmailInput;

type EmailBadgeProps = {
  email: string;
  remove: (value: string) => void;
  edit: (oldValue: string, newValue: string) => void;
};

@observer
class EmailBadge extends React.Component<EmailBadgeProps> {
  @observable isFocused: boolean = false;

  get isValid() {
    return !emailValidator(this.props.email);
  }

  remove = () => {
    const { email, remove } = this.props;
    remove(email);
  };

  onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (KEYS_SEPARATORS.includes(event.key)) {
      event.preventDefault();
      const oldEmail = this.props.email;
      const email = event.currentTarget.innerText.trim();

      if (email) {
        this.props.edit(oldEmail, email);
        event.currentTarget.blur();
      }
    } else if (["Escape"].includes(event.key)) {
      event.currentTarget.blur();
    }
  };

  onFocus = () => {
    this.isFocused = true;
  };

  onBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (this.isFocused) {
      const oldEmail = this.props.email;
      const email = event.currentTarget.innerText.trim();

      if (email) {
        this.props.edit(oldEmail, email);
      } else {
        this.remove();
      }

      this.isFocused = false;
    }
  };

  render() {
    return (
      <Badge isValid={this.isValid}>
        <Email
          contentEditable
          suppressContentEditableWarning
          spellCheck={false}
          onClick={this.onClick}
          onKeyDown={this.onKeyDown}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        >
          {this.props.email}
        </Email>
        <Exit isValid={this.isValid} onClick={this.remove}>
          <Icon icon={<SvgI16Exit />} color="#a9b4c9" />
        </Exit>
      </Badge>
    );
  }
}

function placeCaretAtEnd(element: any) {
  if (
    typeof window.getSelection !== "undefined" &&
    typeof document.createRange !== "undefined"
  ) {
    const range = document.createRange();
    range.selectNodeContents(element);
    range.collapse(false);
    const selection = window.getSelection();
    // eslint-disable-next-line no-unused-expressions
    selection?.removeAllRanges();
    // eslint-disable-next-line no-unused-expressions
    selection?.addRange(range);
  } else if (typeof document.body.createTextRange !== "undefined") {
    const textRange = document.body.createTextRange();
    textRange.moveToElementText(element);
    textRange.collapse(false);
    textRange.select();
  }
}
