import styled from "styled-components";

import InputMask from "react-input-mask";

import styledWrapper from "common/components/StyledWrapper";

export const Container = styled.div`
  width: 100%;

  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Input = styledWrapper(styled(InputMask)`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 48px;

  padding: 0 16px;

  color: ${props => (props.disabled ? "rgba(51, 51, 51, 0.4)" : "#333333")};
  background-color: ${props => (props.disabled ? "#f0f2f7" : "transparent")};

  font-family: OpenSans-Regular;
  font-size: 16px;

  outline: none;

  border-radius: 7px;
  border: ${props =>
    props.hasError ? "solid 2px #f74a70;" : "solid 2px #d8ddeb"};

  &::placeholder {
    color: rgba(51, 51, 51, 0.4);
  }

  &:active {
    border: ${props =>
      props.hasError ? "solid 2px #f74a70;" : "solid 2px #5682f6"};
  }

  &:focus {
    border: ${props =>
      props.hasError ? "solid 2px #f74a70;" : "solid 2px #5682f6"};
  }
`)`  
  min-width: 76px;
  width: 100%;
  height: 48px;
  position: relative;
`;

export const Error = styled.p`
  position: ${({ isStatic }) => (isStatic ? "static" : "absolute")};

  font-size: 13px;

  color: #f74a70;

  margin-top: 4px;
`;

export const Label = styled.span`
  font-size: 14px;
  color: #919bb8;
  margin-left: 8px;
`;

export const Note = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
`;

export const NoteText = styled.span`
  font-size: 14px;
  color: #5682f6;
  margin-left: 6px;
  white-space: nowrap;
`;
