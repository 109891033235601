import styled from "styled-components";

export const Title = styled.h2`
  font-family: OpenSans-Bold;
  font-size: 30px;

  color: #37383d;
`;

export const Text = styled.p`
  font-family: OpenSans;
  font-size: 16px;

  line-height: 1.25;
  letter-spacing: 0.15px;
  color: #333333;

  margin-top: 20px;
`;

export const Error = styled(Text)`
  color: #f74a70;
`;

export const Container = styled.div`
  width: 562px;
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 50px;
`;

export const ButtonWrapper = styled.div`
  width: 120px;
  height: 48px;

  :not(:first-child) {
    margin-left: 20px;
  }
`;
