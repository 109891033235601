import React from "react";

import { DictionaryItem } from "common/types/models";

import RadioButtonDefault from "common/components/RadioButton";

import { Container, Row } from "./styles";

type Props = {
  options: Array<DictionaryItem>;
  selected?: DictionaryItem;
  isSelected?: (option: DictionaryItem) => boolean;
  onChange: (option: DictionaryItem, index: number) => void;
  disabled?: boolean;
};

function RadioButtonGroup({
  options,
  disabled,
  onChange,
  selected,
  isSelected,
}: Props) {
  return (
    <Container disabled={disabled}>
      {options.map((option, index) => (
        <RadioButton
          key={option.value}
          index={index}
          option={option}
          onChange={onChange}
          isSelected={
            isSelected
              ? isSelected(option)
              : !!(selected && selected.value === option.value)
          }
          disabled={disabled}
        />
      ))}
    </Container>
  );
}

export default RadioButtonGroup;

type RadioButtonProps = {
  index: number;
  option: DictionaryItem;
  isSelected: boolean;
  onChange: (option: DictionaryItem, index: number) => void;
  disabled?: boolean;
};

class RadioButton extends React.Component<RadioButtonProps> {
  onClick = () => {
    const { option, onChange, disabled, index } = this.props;
    !disabled && onChange(option, index);
  };

  render() {
    const { isSelected, option } = this.props;
    return (
      <Row>
        <RadioButtonDefault
          onClick={this.onClick}
          isSelected={isSelected}
          label={option.label}
        />
      </Row>
    );
  }
}
