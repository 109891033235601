import React from "react";

import Router from "navigation";

import GlobalPopup from "common/services/popup";

import { version } from "common/../../package.json";
import { environmentName, isNotProduction } from "common/utils/env";

import { Version } from "./styles";

export default class App extends React.Component {
  render(): React.ReactNode {
    return (
      <>
        <Router />
        <GlobalPopup />
        <Version>{isNotProduction && version + "\n" + environmentName}</Version>
      </>
    );
  }
}
