const APP_ENV_FIELD = "REACT_APP_ENV";
const API_URL_FIELD = "REACT_APP_API_URL";

const ENVS = {
  DEVELOPMENT: "development",
  TEST: "test",
  PRODUCTION: "production",
};

export const environmentName = process.env[APP_ENV_FIELD];

export const isNotProduction = environmentName !== ENVS.PRODUCTION;

export const apiUrl = process.env[API_URL_FIELD];
