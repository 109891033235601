import React from "react";

import { DictionaryItem } from "common/types/models";

import CheckboxDefault from "common/components/Checkbox";

import { Container, Row } from "./styles";

type Props = {
  options: Array<DictionaryItem>;
  selected?: Array<DictionaryItem>;
  onChange: (option: Array<DictionaryItem>) => void;
  required?: boolean;
  disabled?: boolean;
};

function CheckboxGroup({
  options,
  disabled,
  onChange,
  selected,
  required,
}: Props) {
  const handleChange = (option: DictionaryItem) => {
    let items = selected || [];
    if (items.includes(option)) {
      items = items.filter(item => item.value !== option.value);
    } else {
      items.push(option);
    }
    required ? items.length > 0 && onChange(items) : onChange(items);
  };

  return (
    <Container disabled={disabled}>
      {options.map((option, index) => (
        <Checkbox
          key={option.value}
          index={index}
          option={option}
          onChange={handleChange}
          isChecked={!!(selected && selected.includes(option))}
          disabled={disabled}
        />
      ))}
    </Container>
  );
}

export default CheckboxGroup;

type CheckboxProps = {
  index: number;
  option: DictionaryItem;
  isChecked: boolean;
  onChange: (option: DictionaryItem, index: number) => void;
  disabled?: boolean;
};

class Checkbox extends React.Component<CheckboxProps> {
  onClick = () => {
    const { option, onChange, disabled, index } = this.props;
    !disabled && onChange(option, index);
  };

  render() {
    const { isChecked, option } = this.props;
    return (
      <Row>
        <CheckboxDefault
          onClick={this.onClick}
          isChecked={isChecked}
          label={option.label}
          accent
        />
      </Row>
    );
  }
}
