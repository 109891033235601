import styled from "styled-components";

import styledWrapper from "common/components/StyledWrapper";
import { LogoBlue } from "common/components/Logo";
import { COLORS } from "common/constants/layout";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  padding: 50px 60px 60px 60px;

  min-width: 590px;
  min-height: 800px;
`;

export const Logo = styledWrapper(LogoBlue)`
  margin-bottom: 50px;
`;

export const Title = styled.p`
  font-family: OpenSans-Bold;
  font-size: 30px;
  color: ${COLORS.GRAY};
  margin-bottom: 18px;
`;

export const Subtitle = styled.p`
  font-family: OpenSans;
  font-size: 18px;
  line-height: 1.44;
  color: ${COLORS.GRAY};
  margin-bottom: 30px;
`;
