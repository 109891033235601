import React, { MouseEvent } from "react";
import { observer } from "mobx-react";
import DefaultDatePicker from "react-datepicker";
import { FieldInputProps } from "react-final-form";

import "react-datepicker/dist/react-datepicker.min.css";

import SvgI16Calendar from "common/assets/icons/I16Calendar";

import {
  Container,
  Error,
  InputContainer,
  Input,
  InputIconContainer,
} from "./styles";

export type DatePickerExternalProps = {
  disabled?: boolean;
  placeholder?: string;
  placement?: string;
  minDate?: Date;
  maxDate?: Date;
  fullWidth?: boolean;
};

type Props = {
  onChange: (date: Date) => void;
  error?: string;
  input: FieldInputProps<Date>;
} & DatePickerExternalProps;

@observer
export default class Datepicker extends React.Component<Props> {
  onChange = (date: Date) => {
    this.props.onChange(date);
  };

  render() {
    const {
      disabled,
      placeholder,
      error,
      input,
      placement,
      minDate,
      maxDate,
      fullWidth,
    } = this.props;

    return (
      <Container fullWidth={fullWidth}>
        <DefaultDatePicker
          {...input}
          selected={input?.value}
          onChange={this.onChange}
          customInput={<CustomInput error={error} fullWidth={fullWidth} />}
          placeholderText={placeholder}
          disabled={disabled}
          popperPlacement={placement}
          minDate={minDate}
          maxDate={maxDate}
          enableTabLoop={false}
          popperModifiers={{
            flip: {
              boundariesElement: "scrollParent",
            },
          }}
        />
        {error && <Error>{error}</Error>}
      </Container>
    );
  }
}

type CustomInputProps = React.HTMLProps<HTMLInputElement> & {
  error?: string;
  fullWidth?: boolean;
};

class CustomInput extends React.Component<CustomInputProps> {
  inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  get input() {
    return this.inputRef && this.inputRef.current;
  }

  onIconClick = (e: MouseEvent<HTMLInputElement>) => {
    this.input && this.input.focus();
    this.props.onClick && this.props.onClick(e);
  };

  render() {
    const {
      name,
      onChange,
      onClick,
      placeholder,
      value,
      error,
      fullWidth,
    } = this.props;
    return (
      <InputContainer onClick={onClick}>
        <Input
          ref={this.inputRef}
          name={name}
          placeholder={placeholder}
          hasError={error}
          value={value}
          onChange={onChange}
          onClick={onClick}
          readOnly
          fullWidth={fullWidth}
        />
        <InputIconContainer onClick={this.onIconClick}>
          <SvgI16Calendar />
        </InputIconContainer>
      </InputContainer>
    );
  }
}
