import React from "react";

import { Container, Title, Subtitle } from "./styles";

type Props = {
  title: string;
  subtitle?: string;
  subtitleLight?: boolean;
};

function FormTitle({ title, subtitle }: Props) {
  return (
    <Container>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Container>
  );
}

export default FormTitle;
