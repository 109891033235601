import React from "react";
import { HashRouter } from "react-router-dom";

import Switch from "./Switch";

type Props = {};

class Router extends React.Component<Props> {
  render() {
    return (
      <HashRouter>
        <Switch />
      </HashRouter>
    );
  }
}

export default Router;
