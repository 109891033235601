import React from "react";
import Spinner from "react-loader-spinner";

import { COLORS } from "common/constants/layout";

import { Container } from "./styles";

type Props = {
  size?: number;
  color?: string;
  className?: any;
};

function Loader({ size = 56, color = COLORS.CORNFLOWER, className }: Props) {
  return (
    <Container className={className}>
      <Spinner type="Oval" color={color} width={size} height={size} />
    </Container>
  );
}

export default Loader;
