import styled from "styled-components";
import { COLORS } from "common/constants/layout";

export const Container = styled.nav`
  display: flex;
  flex-direction: row;

  padding-left: 120px;
`;

export const FixedNavBar = styled.div`
  position: fixed;
  top: 100px;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  width: 120px;
  height: calc(100% - 100px);

  background-color: ${COLORS.BLACK};

  padding: 40px 0 10px;

  z-index: 1;
`;

export const BottomContainer = styled.div`
  flex: 1;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
`;
